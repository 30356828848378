import React, { useEffect, useState } from "react";
import FadeIn from "react-fade-in"
import FamilyService from "../services/family.service";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { isEmail } from "validator";
import { ChooseAvatar } from "../pages/FirstLogin";

export const NoFamily = () => {
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [familyLoading, setFamilyLoading] = useState(false);
    const [request, setRequest] = useState(0);
    const currentUser = AuthService.getCurrentUser();
    const [family, setFamily] = useState(null);

    useEffect(() => {
        getFamily();
    }, []);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const getFamily = async () => {
        setFamilyLoading(true);
        AuthService.refresh(currentUser.userId);
        await delay(1000);
        FamilyService.getFamily(currentUser.userId)
            .then(response => {
                console.log(response.data)
                if (response.data === "none") {
                    setFamily(response.data);
                    console.log(response.data);
                    setFamilyLoading(false);
                } else {
                    navigate("/family")
                    window.location.reload();
                }
            })
            .catch(e => {
                console.log(e);
                setFamilyLoading(false);
            });
    };

    return (
        <div className="container-fluid">
            <div className="list row">
                <div className="col-md-6">
                    <FadeIn>
                        {currentUser.firstLogin === false ?
                            <div className="col-md-6">
                                <h3 style={{
                                    "textAlign": "center"
                                }}>You need a Family!</h3>
                                <span>
                                    Before you can get started with creating and managing tasks, you need to
                                    be a member of a family!
                                </span>
                                <p />
                                <span>
                                    If you want to <strong>JOIN</strong> an existing family, ask the Family Admin
                                    to send you an invite and look for the invite in your e-mail!
                                </span>
                                <p />
                                <span>
                                    If you want to <strong>CREATE</strong> a brand new family, hit the button below to get started!
                                </span>
                                <p />
                                <div>
                                    <Link
                                        to={"/family/create"}
                                        className="btn btn-success"
                                    >
                                        Create a New Family
                                    </Link>
                                </div>
                            </div>
                            :
                            <ChooseAvatar />
                        }
                    </FadeIn>
                </div>
            </div>
        </div>
    );
};