import React from "react";
import {NavBarBrand} from "./NavBarBrand";

export const NavBar = () => {
 return (
     <nav className="navbar navbar-expand bg-primary navbar-dark ms-auto" style={{
         "--bs-navbar-padding-y": '0'
     }}>
         <NavBarBrand/>
     </nav>
 )
}