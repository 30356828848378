import api from "./api"

const upload = (userId, file, onUploadProgress) => {
    let formData = new FormData();

    formData.append("file", file);

    return api.post(`/file/upload/${userId}`, formData, {
        headers:
            {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,

    });
};

const getFiles = () => {
    return api.get("/file");
};

const getAvatar = () => {
    return api.get(`/file/avatar`);
};

export default {
    upload,
    getFiles,
    getAvatar
};