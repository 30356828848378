import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";
import "../../App.css";
import { Capacitor } from '@capacitor/core';


export const NavBarBrandBottom = () => {
    const [currentPage, setCurrentPage] = useState("");
    const [showAuthenticated, setShowAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);
    const user = AuthService.getCurrentUser();
    //const url = "http://localhost";

    useEffect(() => {
        setCurrentPage(window.location.href);
    });

    useEffect(() => {
        //const user = AuthService.getCurrentUser();
        setCurrentPage(window.location.href);

        if (Capacitor.isNativePlatform()) {
            const url = "http://localhost";
        } else {
            const url = "https://honee-do.com";
        }

        if (user) {
            setCurrentUser(user);
            setShowAuthenticated(user.roles.includes("ROLE_ADMIN"));
        }

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);

    const logOut = () => {
        setShowAuthenticated(false);
        setCurrentUser([]);
    };

    return (
        <div className="container-fluid" style={{
            "justify-content": 'center'
        }}>
            {currentUser &&
                <div className="container">
                    {currentUser.familyId != null ?
                        <div style={{ "width": "100%" }} classname="row ">
                            <ul className="navbar-nav mb-lg-0 d-flex justify-content-around" style={{
                                "font-size": '12px',
                            }}>
                                <li style={(currentPage.endsWith(`/#/add`) ? { "transform": "scale(1.2)" } : { "transform": "scale(1)" })} className="nav-item animate-transform">
                                    <Link to={(currentUser.familyId != null ? "/add" : "/needfamily")} className="nav-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={(currentPage.endsWith(`/#/add`) ? "lightblue" : "white")}
                                            className="bi bi-plus-square-fill ms-1" viewBox="0 0 16 16">
                                            <path
                                                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                        </svg>
                                        <br />
                                        <span style={(currentPage.endsWith(`/#/add`) ?
                                            {
                                                "color": "lightblue"
                                            }
                                            :
                                            {
                                                "color": "white"
                                            })}>Add</span>
                                    </Link>
                                </li>
                                <li style={(currentPage.endsWith(`/#/tasks`) ? { "transform": "scale(1.2)" } : { "transform": "scale(1)" })} className="nav-item animate-transform text-center">
                                    <Link to={"/tasks"} className="nav-link">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={(currentPage.endsWith(`/#/tasks`) ? "lightblue" : "white")} class="bi bi-check-square-fill" viewBox="0 0 16 16">
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                        </svg>
                                        <br />
                                        <span style={(currentPage.endsWith(`/#/tasks`) ?
                                            {
                                                "color": "lightblue"
                                            }
                                            :
                                            {
                                                "color": "white"
                                            })}>Tasks</span>
                                    </Link>
                                </li>
                                <li style={(currentPage.endsWith(`/#/family`) ? { "transform": "scale(1.2)" } : { "transform": "scale(1)" })} className="nav-item animate-transform">
                                    <Link to={"/family"} className="nav-link">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={(currentPage.endsWith(`/#/family`) ? "lightblue" : "white")}
                                            className="bi bi-people-fill ms-1" viewBox="0 0 16 16">
                                            <path
                                                d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238lightblue 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                        </svg>
                                        <br />
                                        <span style={(currentPage.endsWith(`/#/family`) ?
                                            {
                                                "color": "lightblue"
                                            }
                                            :
                                            {
                                                "color": "white"
                                            })}>Family</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        :
                        null
                    }
                </div>
            }
        </div>
    );
};