import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import FamilyService from "../services/family.service";
import { NavBar } from "../components/navigation/NavBar";
import FadeIn from "react-fade-in";
import { PageLayout } from "../components/PageLayout";
import { BaseLayout } from "../components/BaseLayout";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const ForgotPassword = () => {

    const form = useRef();
    const checkBtn = useRef();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [submit, setSubmit] = useState(false);

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            FamilyService.forgotPassword(email).then(
                () => {
                    setSubmit(true);
                    //navigate("/login");
                    //window.location.reload();
                },
            );
        }
    };

    return (
        <div className="container-fluid" style={{
            "max-width": '300px'
        }}>
            <FadeIn>

                <div className="container">
                    <h1 className="mt-5" style={{
                        "font-size": '50px',
                        "color": 'white',
                        "font-family": 'Pacifico'
                    }}>
                        <center>
                            Honee-Do!
                        </center>
                    </h1>
                    <h2>
                        Forgot your password?
                    </h2>
                    <div>
                        Enter your e-mail address registered with Honee-Do below and hit the
                        Submit button to get a reset link sent to your inbox.
                    </div>
                    <Form onSubmit={handleForgotPassword} ref={form}>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={onChangeEmail}
                                validations={[required, validEmail]}
                            />
                        </div>
                        <p />
                        <div className="form-group">
                            <button className="btn btn-success btn-block" disabled={loading}>
                                <span>Submit</span>
                            </button>
                            <button className="btn btn-secondary ms-3">
                                <Link to={"/login"} className="nav-link">
                                    Return to Sign In
                                </Link>
                            </button>
                        </div>
                        <p />
                        {submit === true &&
                            <FadeIn>
                                <div className="form-group">
                                    <div className="alert alert-success" role="alert">
                                        Request received! If the e-mail you entered matches the e-mail for your account,
                                        then Honee-Do will send a Reset Password link to your e-mail inbox.
                                    </div>
                                    <button className="btn btn-primary">
                                        <Link to={"/login"} className="nav-link">
                                            Return to Sign In
                                        </Link>
                                    </button>
                                </div>
                            </FadeIn>
                        }
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </FadeIn>
        </div>
    );
};

export default ForgotPassword;