import React, { useEffect, useState } from "react";
import FamilyService from "../services/family.service";
import FadeIn from "react-fade-in";
import { useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import AuthService from "../services/auth.service";
import { NavBarBottom } from "../components/navigation/NavBarBottom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const Request = () => {
    const currentUser = AuthService.getCurrentUser();
    let navigate = useNavigate();
    const { token } = useParams();
    const decoded = jwtDecode(token);
    const initialRequestState = {
        email: "",
        mediaLink: "",
        requestId: "",
        userId: "",
        description: ""
    };
    const [request, setRequest] = useState(initialRequestState);
    const [currentRequest, setCurrentRequest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAcceptInvite, setShowAcceptInvite] = useState(false);
    const [showDeclineInvite, setShowDeclineInvite] = useState(false);

    const handleInviteAcceptOpen = () => setShowAcceptInvite(true);
    const handleInviteAcceptClose = () => setShowAcceptInvite(false);
    const handleInviteDeclineOpen = () => setShowDeclineInvite(true);
    const handleInviteDeclineClose = () => setShowDeclineInvite(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        getInvite();
    }, []);

    const getInvite = async () => {
        FamilyService.getInvite(decoded.inviteId)
            .then(response => {
                setRequest(response.data);
                setLoading(false);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const acceptInvite = async () => {
        setLoading(true);
        setShowAcceptInvite(false);
        FamilyService.acceptInvite(request[0].requestId)
            .then(response => {
                AuthService.refresh(currentUser.userId);
            })
            .catch(e => {
                console.log(e);
            });
        await delay(1000);
        navigate("/family");
        window.location.reload();
    };

    const declineInvite = () => {
        FamilyService.declineRequest(currentRequest.requestId)
            .then(response => {
                navigate("/family");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div>
            <p />
            <div className="list row">
                {loading === false ?
                    <div className="col text-center">
                        <FadeIn>
                            <div className="container-flex" style={{
                                "margin-top": "8rem"
                            }}>
                                <h4 style={{
                                    "font-size": '34px'
                                }}>
                                    Join the {request[0].familyName} family!</h4>
                                <div>
                                    <span>
                                        {request[0].description}
                                    </span>
                                    <img className="img border border-secondary mt-3" src={request[0].mediaLink} alt={request[0].mediaLink} height="180px" />
                                    <div className="container">
                                        <div className="row">
                                            <div className="container-fluid" style={{
                                                "justify-content": 'center'
                                            }}>
                                                <div style={{ "width": "100%" }} classname="row">
                                                    <ul className="d-flex justify-content-center" style={{
                                                        "font-size": '12px',
                                                        "padding-inline-start": "0px"
                                                    }}>

                                                        <div className="mt-2">
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={handleInviteAcceptOpen}
                                                                style={{
                                                                    "--bs-btn-line-height": "1.3",
                                                                    "--bs-btn-border-radius": "4rem",
                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                                                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                                                </svg>
                                                                <div style={{
                                                                    "fontSize": "13px"
                                                                }}>
                                                                    <div><strong>Accept</strong></div>
                                                                </div>
                                                            </button>
                                                            <button
                                                                className="btn btn-danger ms-2"
                                                                onClick={handleInviteDeclineOpen}
                                                                style={{
                                                                    "--bs-btn-line-height": "1.3",
                                                                    "--bs-btn-border-radius": "4rem",
                                                                    "--bs-btn-padding-x": "0.95rem"
                                                                }}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                                </svg>
                                                                <div style={{
                                                                    "fontSize": "13px"
                                                                }}>
                                                                    <div><strong>Decline</strong></div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FadeIn>
                        <div>
                            <Modal centered show={showAcceptInvite} onHide={handleInviteAcceptClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Accept the Invite?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <span>Are you sure you want to accept this invite and join the {request[0].familyName} family? </span>
                                    {currentUser.familyId !== null ?
                                        <span>
                                            You will be removed from your current family and leave behind all of your existing tasks.
                                        </span>
                                        :
                                        null
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleInviteAcceptClose}>
                                        Cancel
                                    </Button>
                                    <Button variant="success" onClick={acceptInvite}>
                                        Yes, Accept!
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal centered show={showDeclineInvite} onHide={handleInviteDeclineClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Decline the Invite?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <span>Are you sure you want to decline this invite to join the {request[0].familyName} family? </span>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleInviteDeclineClose}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={declineInvite}>
                                        Yes, Decline.
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    :
                    <div className="col-md-6 text-center">
                        <div className="container-fluid" style={{
                            "margin-top": "15rem"
                        }}>
                            <div className="spinner-border text-primary" style={{ "width": "11.1rem", "height": "11.1rem" }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <footer className="footer" style={{ "overflow-anchor": 'none' }}>
                <NavBarBottom />
            </footer>
        </div>
    );
};