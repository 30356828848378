import api from "./api"

class FamilyService {

    // Create Family
    create(data) {
        return api.post("/family/create", data);
    }

    // Invite Member
    invite(email) {
        return api.post("/family/invite",
            {email});
    }

    // Update User Info
    update(firstname, lastname) {
        return api.post("/family/update",
            {firstname, lastname});
    }

    // Set Email Alerts
    setNotification(email_alerts) {
        return api.post("/family/set_notification",
            {email_alerts});
    }

    // Set Email Alerts
    getNotificationSettings() {
        return api.get("/family/get_notification");
    }

    // List all Family Members
    listMembers() {
        return api.get(`/family`);
    }

    // Get all join requests
    getInvite(requestId) {
        return api.get(`/family/requests/${requestId}`);
    }

    // Get all join requests
    checkRequests() {
        return api.get(`/family/check_requests`);
    }

    // Get Family Name
    getFamily(userId) {
        return api.get(`/family/${userId}`);
    }

    // Check first login status
    checkFirstLogin() {
        return api.get(`/family/firstlogin`);
    }


    // Assign new Admin
    assignNewAdmin(userId) {
        return api.post(`/family/reassign_admin/${userId}`);
    }

    // Forgot Password
    forgotPassword(email) {
        return api.post(`/family/forgot`, {email});
    }

    // Forgot Password
    changeForgotPassword(email, password) {
        return api.post(`/family/forgot/change`, {email, password});
    }

    // Change Password
    changePassword(currentPassword, email, password) {
        return api.post(`/family/change`, {currentPassword, email, password});
    }

    //Request to join family
    request(email) {
        return api.post(`/family/request`, {email});
    }

    // Accept request to join a family
    acceptRequest(requestId) {
        return api.post(`/family/join/request`, {requestId});
    }

    // Accept request to join a family
    acceptInvite(requestId) {
        return api.post(`/family/join/invite`, {requestId});
    }

    // Decline family join
    declineRequest(requestId) {
        return api.post(`/family/decline`, {requestId});
    }

    // Delete family member
    remove(userId) {
        return api.delete(`/family/remove/${userId}`);
    }

    // Leave current family
    leave() {
        return api.delete(`/family/leave`);
    }

    // Delete family
    delete(familyId) {
        return api.delete(`/family/delete/${familyId}`);
    }

    changeFirstLogin(userId) {
        return api.post(`/family/firstlogin`, {userId});
    }
}

export default new FamilyService();