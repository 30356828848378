import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import fileUploadService from "../services/file-upload.service";
import Avatar from "react-avatar";
import familyService from "../services/family.service";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Profile = () => {
  const currentUser = AuthService.getCurrentUser();
  const [avatar, setAvatar] = useState("");
  const [fullName, setFullName] = useState("");
  const [getEmailAlerts, setGetEmailAlerts] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    AuthService.refresh(currentUser.userId);
    getAvatar();
    getNotificationSettings();
  }, []);

  const setSwitch = () => {
    if (getEmailAlerts === 1) {
      let emailAlert = 0;
      setGetEmailAlerts(emailAlert);
    } else {
      let emailAlert = 1;
      setGetEmailAlerts(emailAlert);
    }
  };

  const sendNotificationPreference = () => {
    setShow(false);
    familyService.setNotification(getEmailAlerts)
      .then(response => {
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getAvatar = async () => {
    setLoading(true);
    await delay(1000);
    fileUploadService.getAvatar()
      .then(response => {
        console.log(response.data);
        setAvatar(response.data);
        setLoading(false);
        setFullName(currentUser.firstname + " " + currentUser.lastname)
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const getNotificationSettings = async () => {
    familyService.getNotificationSettings()
      .then(response => {
        console.log(response.data[0].email_alerts);
        setGetEmailAlerts(response.data[0].email_alerts);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div>
      <p />
      <FadeIn>
        <div className="list row" style={{
          "--bs-gutter-x": "10.735rem"
        }}>
          <div className="col text-center">
            <header className="jumbotron">
              <h3>
                {currentUser.firstname} {currentUser.lastname}
              </h3>
            </header>
            <div>
              {loading ?
                <div className="spinner-border text-primary" style={{ "width": "11.1rem", "height": "11.1rem" }} role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <FadeIn>
                  {avatar.length > 77 ?
                    <img className="img border border-secondary" src={avatar} alt={avatar} height="180px" />
                    :
                    <Avatar name={fullName} round={true} size="180" textSizeRatio={3} />
                  }
                </FadeIn>
              }
            </div>
            <p />
            <div className="container-flex">
              <button className="btn btn-success">
                <Link to={"/avatar"} className="nav-link">
                  Change Profile Photo
                </Link>
              </button>
            </div>
            <p />
            <FadeIn>
              <p />
              <button className="btn btn-primary">
                <Link to={"/update"} className="nav-link">
                  Update Personal Info
                </Link>
              </button>
              <p />
              <button className="btn btn-primary">
                <Link to={"/change"} className="nav-link">
                  Change Password
                </Link>
              </button>
              <p />
              <div>
                <Button variant="primary" onClick={handleShow}>
                  Notification Settings
                </Button>
                <Modal centered show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Notification Settings</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-check form-switch">
                      {getEmailAlerts === 1 ?
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                          onClick={() => setSwitch()} checked
                        />
                        :
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                          onClick={() => setSwitch()}
                        />
                      }
                      <div className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        <label>Receive alerts by e-mail</label>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={sendNotificationPreference}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <p />
              <button className="btn btn-secondary">
                <Link to={"/tasks"} className="nav-link">
                  Back
                </Link>
              </button>
            </FadeIn>
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default Profile;