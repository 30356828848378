import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import TaskService from "../services/task.service";
import FamilyService from "../services/family.service";
import DatePicker from 'react-datepicker';
import 'add-to-calendar-button';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FadeIn from "react-fade-in/lib/FadeIn";

const TaskView = props => {
    const { id } = useParams();
    let navigate = useNavigate();
    const initialTaskState = {
        taskId: null,
        familyId: "",
        userId: "",
        ownerId: "",
        title: "",
        description: "",
        completed: false,
        dueDate: new Date(),
        taskupdate: ""
    };
    const [currentTask, setCurrentTask] = useState(initialTaskState);
    const [message, setMessage] = useState("");
    const [members, setMembers] = useState([]);
    const [owners, setOwners] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [currentMember, setCurrentMember] = useState([]);
    const [currentOwner, setCurrentOwner] = useState([]);
    const [currentMemberIndex, setCurrentMemberIndex] = useState(-1);
    const [currentOwnerIndex, setCurrentOwnerIndex] = useState(-1);
    const [change, setChange] = useState(false);
    const [noDueDate, setNoDueDate] = useState(true);

    const [showDeleteTask, setDeleteTask] = useState(false);
    const handleDeleteTaskOpen = () => setDeleteTask(true);
    const handleDeleteTaskClose = () => setDeleteTask(false);
    const [showViewUpdates, setViewUpdates] = useState(false);
    const handleViewUpdatesOpen = () => setViewUpdates(true);
    const handleViewUpdatesClose = () => setViewUpdates(false);

    // Retrieve Members
    useEffect(() => {
        retrieveMembers();
    }, []);

    function formatTime(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleTimeString([], options);
    }

    const retrieveMembers = () => {
        FamilyService.listMembers()
            .then(response => {
                setMembers(response.data);
                setOwners(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const setActiveMember = (member, index) => {
        setCurrentMember(member);
        setCurrentMemberIndex(index);
        setChange(true);
    };

    const getTask = taskId => {
        TaskService.get(taskId)
            .then(response => {
                setCurrentTask(response.data);
                setCurrentMember({ ...currentMember, userId: response.data.ownerId });
                setCurrentOwner({ ...currentOwner, userId: response.data.userId });
                if (currentTask.dueDate === null) {
                    setNoDueDate(true);
                }
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (id)
            getTask(id);
    }, [id]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentTask({ ...currentTask, [name]: value });
        setChange(true);
    };

    const getAllUpdates = () => {
        handleViewUpdatesOpen();
        TaskService.getAllUpdates(currentTask.taskId)
            .then(response => {
                setUpdates(response.data)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const deleteTask = () => {
        TaskService.remove(currentTask.taskId)
            .then(response => {
                console.log(response.data);
                navigate("/tasks");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div className="list row">
            <div className="col-md-6">
                <FadeIn>
                    <h4>Task</h4>
                    {currentTask.title ? (
                        <div className="edit-form">
                            <div>
                                {currentTask.taskUpdate == null ?
                                    null
                                    :
                                    <div>
                                        <div style={{ "font-size": '13px' }}>
                                            <strong>Latest Update</strong> - {formatTime(currentTask.createdAt)}
                                        </div>
                                        <div className="alert alert-success text-break"
                                            style={{
                                                "white-space": 'pre-wrap'
                                            }}
                                        >
                                            "{currentTask.taskUpdate}"
                                            <div className="align-right" style={{ "font-size": '13px' }}>-From <strong>{currentTask.firstname} {currentTask.lastname}</strong></div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="title" class="form-label">
                                        <strong>Title</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={currentTask.title}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description" class="form-label">
                                        <strong>Description</strong>
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        rows="4"
                                        id="description"
                                        name="description"
                                        value={currentTask.description}
                                        disabled
                                    />
                                </div>
                                <ul className="list-group">
                                    <label htmlFor="owner" className="form-label">
                                        <strong>Current Owner: </strong>
                                    </label>
                                    {members &&
                                        members.map((member, index) => (
                                            <div>
                                                {member.userId === currentMember.userId ?
                                                    <li
                                                        className={
                                                            "list-group-item " + (member.userId == currentMember.userId ? "active" : "")
                                                        }
                                                        onClick={() => setActiveMember(member, index)}
                                                        onChange={handleInputChange}
                                                        key={index}
                                                    >
                                                        {member.firstname} {member.lastname}
                                                    </li>
                                                    :
                                                    null
                                                }
                                            </div>

                                        ))}
                                </ul>
                                <div className="form-group" style={{ position: "relative", zIndex: "999" }}>
                                    <label htmlFor="dueDate" className="form-label">
                                        <strong>Due Date</strong>
                                    </label>
                                    {noDueDate == 1 ?
                                        <DatePicker
                                            name="dueDate"
                                            dateFormat="yyyy-MM-dd"
                                            value="No Due Date"
                                            popperPlacement="top-start"
                                            disabled
                                        />
                                        :
                                        <DatePicker
                                            name="dueDate"
                                            dateFormat="yyyy-MM-dd"
                                            //value={currentTask.dueDate.toString().split("T")[0]}
                                            popperPlacement="top-start"
                                            disabled
                                        />
                                    }
                                </div>
                                <p />
                                <div className="form-check">
                                    {currentTask.completed ? (
                                        <div>
                                            <input className="form-check-input" type="checkbox" value=""
                                                id="flexCheckDefault"
                                                checked
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Task Completed
                                            </label>
                                        </div>
                                    ) : (
                                        <div>
                                            <input className="form-check-input" type="checkbox" value=""
                                                id="flexCheckDefault"
                                                disabled
                                            />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Task Completed
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <p />
                            </form>
                            <div className="container-fluid" style={{
                                "justify-content": 'center'
                            }}>
                                <div style={{ "width": "100%" }} classname="row">
                                    <ul className="d-flex justify-content-center" style={{
                                        "fontSize": '10px',
                                        "padding-inline-start": "0px"
                                    }}>
                                        <button className="btn btn-secondary"
                                            style={{
                                                "--bs-btn-line-height": "1.6",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem",
                                                "fontSize": "10px"
                                            }}>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                                    <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                                                    <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                                                </svg>
                                                <div style={{
                                                    "fontSize": "10px"
                                                }}>
                                                    <Link to={"/tasks"} className="nav-link">
                                                        <strong>Back</strong>
                                                    </Link>
                                                </div>
                                            </div>

                                        </button>
                                        <button
                                            className="btn btn-primary ms-2 me-1"
                                            onClick={getAllUpdates}
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem",
                                                "fontSize": "10px"
                                            }}
                                        >
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                                <div style={{
                                                    "fontSize": "10px"
                                                }}>
                                                    <strong>Updates</strong>
                                                </div>
                                            </div>
                                        </button>
                                        {noDueDate == 1 ?
                                            null
                                            :
                                            <add-to-calendar-button
                                                name={currentTask.title}
                                                description={currentTask.description}
                                                options="'Apple','Google','Microsoft365'"
                                                //location="World Wide Web"
                                                //startDate={currentTask.dueDate.split("T")[0]}
                                                //timeZone="America/Los_Angeles"
                                                buttonStyle="round"
                                                size="3"
                                                styleLight="--btn-shadow: rgba(0 0 0) 0px 3px 5px -1px;"
                                                lightMode="bodyScheme"
                                                label="Calendar"
                                            >
                                            </add-to-calendar-button>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <p>{message}</p>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <div className="container-fluid">
                                <h4 style={{
                                    "margin-top": "15rem",
                                    "textAlign": "center"
                                }}>
                                    Task not found!
                                </h4>
                                <p />
                                <div className="container-fluid text-center">
                                    <button className="btn btn-secondary">
                                        <Link to={"/tasks"} className="nav-link">
                                            Back
                                        </Link>
                                    </button>
                                </div>

                            </div>

                        </div>
                    )}
                </FadeIn>
            </div>
            <Modal centered show={showDeleteTask} onHide={handleDeleteTaskClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete task?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Are you sure you want to delete this task?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteTask}>
                        Yes, Delete.
                    </Button>
                    <Button variant="secondary" onClick={handleDeleteTaskClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showViewUpdates} onHide={handleViewUpdatesClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Updates</Modal.Title>
                </Modal.Header>
                {currentTask.taskUpdate != null ?
                    <Modal.Body>
                        <label style={{ "font-size": '13px' }}>
                            <strong>Latest
                                Update</strong> - {formatTime(currentTask.createdAt)}
                        </label>
                        <div
                            className="alert alert-success text-break"
                            style={{ "white-space": 'pre-wrap' }}>
                            "{currentTask.taskUpdate}"
                            <div className="align-right"
                                style={{ "font-size": '13px' }}>-From <strong>{currentTask.firstname} {currentTask.lastname}</strong>
                            </div>
                        </div>
                        {updates &&
                            updates.map((update, index) => (
                                <div key={"b" + index}>
                                    <label
                                        style={{ "font-size": '13px' }}>
                                        {formatTime(currentTask.createdAt)}
                                    </label>
                                    <div
                                        className="alert alert-secondary text-break"
                                        style={{ "white-space": 'pre-wrap' }}
                                    >
                                        "{update.oldUpdate}"
                                        <div
                                            className="align-right"
                                            style={{ "font-size": '13px' }}>-From <strong>{update.firstname} {update.lastname}</strong>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Modal.Body>
                    :
                    null
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleViewUpdatesClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
};

export default TaskView;
