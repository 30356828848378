import React, { useRef, useState, useEffect } from "react";
import FamilyService from "../services/family.service";
import FadeIn from "react-fade-in";
import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isStrongPassword } from "validator";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const vPasswordComplexity = (value) => {
    if (!isStrongPassword(value, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
        return (
            <div className="alert alert-danger" role="alert">
                Password must be between 8 and 24 characters consisting of at least one of each of the following: uppercase,
                lowercase, number, and special character.
            </div>
        );
    }
};

const ChangePassword = () => {
    const form = useRef();
    const checkBtn = useRef();
    let navigate = useNavigate();
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const currentUser = AuthService.getCurrentUser();

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
    }, []);

    const onChangeCurrentPassword = (e) => {
        const currentPassword = e.target.value;
        setCurrentPassword(currentPassword);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
    };

    const vConfirmPassword = (value) => {
        if (value === password) {
            return (
                <div className="alert alert-danger" role="alert">
                    The passwords don't match! Try entering again.
                </div>
            );
        }
    };

    const handleChangePassword = (e) => {
        var email = currentUser.email;

        e.preventDefault();

        setMessage("");
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            FamilyService.changePassword(currentPassword, email, password).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );

        }
    };

    return (
        <div className="list row">
            <div className="col-md-6">
                <FadeIn>
                    <h3>Change your Password</h3>
                    <div>
                        First, enter your CURRENT password.
                    </div>
                    <Form onSubmit={handleChangePassword} ref={form}>
                        {!successful && (
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="currentPassword"><i>Current Password</i></label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="currentPassword"
                                        value={currentPassword}
                                        onChange={onChangeCurrentPassword}
                                        validations={[required]}
                                    />
                                </div>
                                <p />
                                <div>
                                    Then, enter and re-renter your new password and hit Submit!
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="password"><i>Password</i></label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={password}
                                        onChange={onChangePassword}
                                        validations={[required, vPasswordComplexity]}
                                    />
                                </div>
                                <p />
                                <div className="form-group col-6">
                                    <label htmlFor="confirmPassword"><i>Re-enter Password</i></label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={onChangeConfirmPassword}
                                        validations={[required, vConfirmPassword]}
                                    />
                                </div>
                                <p />
                                <div className="form-group">
                                    {confirmPassword === password ?
                                        <div>
                                            {password == 0 ?
                                                <button className="btn btn-success btn-block disabled">Submit</button>
                                                :
                                                <button className="btn btn-success btn-block">Submit</button>
                                            }
                                            <button className="btn btn-secondary ms-3">
                                                <Link to={"/profile"} className="nav-link">
                                                    Cancel
                                                </Link>
                                            </button>
                                        </div>
                                        :
                                        <div>
                                            <button className="btn btn-success btn-block disabled">Submit</button>
                                            <button className="btn btn-secondary ms-3">
                                                <Link to={"/profile"} className="nav-link">
                                                    Cancel
                                                </Link>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <p />
                        {message && (
                            <div className="form-group">
                                <div
                                    className={
                                        successful ? "alert alert-success" : "alert alert-danger"
                                    }
                                    role="alert"
                                >
                                    {message}
                                </div>
                                <p />
                                <button className="btn btn-primary">
                                    <Link to={"/profile"} className="nav-link">
                                        Return
                                    </Link>
                                </button>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </FadeIn>
            </div>
        </div>
    );
};

export default ChangePassword;