import React from "react";
import FadeIn from "react-fade-in"
import ImageUpload from "../components/FileUpload";


export const ChooseAvatar = () => {

    return (
        <FadeIn>
            <div className="list row">
                <ImageUpload />
            </div>
        </FadeIn>
    );
};