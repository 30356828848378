import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import FadeIn from "react-fade-in";
import { isStrongPassword } from "validator";
import { Link, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vfirstname = (value) => {
  if (value.length < 1 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        Your first name must be between 3 and 20 characters!
      </div>
    );
  }
};

const vlastname = (value) => {
  if (value.length < 1 || value.length > 30) {
    return (
      <div className="alert alert-danger" role="alert">
        Your last name must be between 3 and 20 characters!
      </div>
    );
  }
};

const vPasswordComplexity = (value) => {
  if (!isStrongPassword(value, {
    minLength: 8, minLowercase: 1,
    minUppercase: 1, minNumbers: 1, minSymbols: 1
  })) {
    return (
      <div className="alert alert-danger" role="alert">
        Password must be between 8 and 24 characters consisting of at least one of each of the following: uppercase,
        lowercase, number, and special character.
      </div>
    );
  }
};

const Register = () => {
  const form = useRef();
  const checkBtn = useRef();
  const { token } = useParams();
  const decoded = jwtDecode(token);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState(decoded.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };

  const vConfirmPassword = (value) => {
    if (value === password) {
      return (
        <div className="alert alert-danger" role="alert">
          The passwords don't match! Try entering again.
        </div>
      );
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(firstname, lastname, email, password).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  return (
    <div className="container-fluid" style={{
      "max-width": '300px'
    }}>
      <p />
      <p />
      <FadeIn>
        <h1 className="mt-5" style={{
          "font-size": '50px',
          "color": '#5C80BC',
          "font-family": 'Pacifico'
        }}
        >
          <center>
            Honee-Do!
          </center>
        </h1>
        <div style={{
          //"color": 'white'
        }}>
          <FadeIn>
            <span>
              Get started with Honee-Do now! It's easy to sign up and completely FREE!
            </span>
            <Form onSubmit={handleRegister} ref={form}>
              {!successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="firstname">First Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="firstname"
                      value={firstname}
                      onChange={onChangeFirstname}
                      validations={[required, vfirstname]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="lastname">Last Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="lastname"
                      value={lastname}
                      onChange={onChangeLastname}
                      validations={[required, vlastname]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      disabled
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[required, vPasswordComplexity]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Re-enter Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={onChangeConfirmPassword}
                      validations={[required, vConfirmPassword]}
                    />
                  </div>
                  <p />
                  <div className="form-group">
                    {confirmPassword === password ?
                      <div>
                        {password === 0 ?
                          <div className="d-grid gap-2">
                            <button className="btn btn-success btn-block disabled">Sign Up</button>
                            <button className="btn btn-secondary">
                              <Link to={"/login"} className="nav-link">
                                Return to Sign In
                              </Link>
                            </button>
                          </div>
                          :
                          <div className="d-grid gap-2">
                            <button className="btn btn-success btn-block">Sign Up</button>
                            <button className="btn btn-secondary">
                              <Link to={"/login"} className="nav-link">
                                Return to Sign In
                              </Link>
                            </button>
                          </div>
                        }
                      </div>
                      :
                      <div className="d-grid gap-2">
                        <button className="btn btn-success btn-block disabled">Sign Up</button>
                        <button className="btn btn-secondary">
                          <Link to={"/login"} className="nav-link">
                            Return to Sign In
                          </Link>
                        </button>
                      </div>
                    }
                  </div>
                </div>
              )}
              <p />
              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                  <p />
                  <button className="btn btn-success">
                    <Link to={"/login"} className="nav-link">
                      Return to Sign In
                    </Link>
                  </button>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </FadeIn>
        </div>
      </FadeIn>
    </div>
  );
};

export default Register;