import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from '@capacitor/app';


export const AppUrlListener = () => {
    let navigate = useNavigate();
    useEffect(() => {
      App.addListener("appUrlOpen", event => {
        const slug = event.url.split(".com/#/").pop()
        if (slug) {
          navigate(slug);
        } else {
          navigate("/tasks");
        }
      })
    }, [])
  
    return null
  };
  