import React, { useEffect, useState } from "react";
import FamilyService from "../services/family.service";
import FadeIn from "react-fade-in";
import { Link, useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";


export const Join = () => {
    const { token } = useParams();
    let navigate = useNavigate();
    const decoded = jwtDecode(token);
    const [family, setFamily] = useState([]);

    useEffect(() => {
    });

    const joinFamily = () => {

        FamilyService.joinFamily(token)
            .then(response => {
                console.log(response.data);
                navigate("/family");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div>
            <FadeIn>
                <p />
                <div className="list row">
                    <div className="col-md-6">

                        <div>
                            <div>
                                <h4>Join the {decoded.familyName} Family!</h4>
                                <FadeIn>
                                    <span>
                                        You've been invited by <strong>{decoded.firstname} {decoded.lastname}</strong> to
                                        join the <strong>{decoded.familyName}</strong> family!
                                        Click the Accept button below to join their family.
                                    </span>
                                    <p />
                                    <span>
                                        If you don't want to join this family or weren't expecting this invite,
                                        then hit the Decline button.
                                    </span>
                                    <p />
                                    <button type="button" className="btn btn-success me-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#accept"
                                    >
                                        Accept Invite
                                    </button>
                                    <p />
                                    <button type="button" className="btn btn-danger">
                                        <Link to={"/family"} className="nav-link">
                                            Decline Invite
                                        </Link>
                                    </button>
                                    <div className="modal fade" id="accept"
                                        aria-labelledby="acceptModal" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h1 className="modal-title fs-5" id="acceptModal">
                                                        Join {decoded.familyName} Family?
                                                    </h1>
                                                    <button type="button" className="btn-close"
                                                        data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    Are you sure you want to join the {decoded.familyName} family?
                                                    You will leave your current family (if you're in one) and any tasks
                                                    you own will be left behind.
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-danger"
                                                        data-bs-dismiss="modal">Do Not Join
                                                    </button>
                                                    <button type="button" className="btn btn-success"
                                                        data-bs-dismiss="modal"
                                                        onClick={joinFamily}>Join
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FadeIn>
                                <div className="modal fade" id="reassign"
                                    aria-labelledby="reassignModal" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h1 className="modal-title fs-5" id="reassignModal">
                                                    Reassign Family Admin First!
                                                </h1>
                                                <button type="button" className="btn-close"
                                                    data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                You're already the admin of a family! You'll need to either reassign
                                                your Family Admin role to someone else or delete your family before
                                                you can accept this invite. Do that first then come back to this
                                                invite.
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary"
                                                    data-bs-dismiss="modal">
                                                    Close
                                                </button>
                                                <button type="button" className="btn btn-success"
                                                    data-bs-dismiss="modal">
                                                    <Link to={"/family"} className="nav-link">
                                                        View my Family
                                                    </Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </FadeIn>
        </div>
    );
};