import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import TaskService from "../services/task.service";
import FamilyService from "../services/family.service";
import DatePicker from 'react-datepicker';
import Avatar from "react-avatar";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FadeIn from "react-fade-in/lib/FadeIn";

const TaskEdit = props => {
    const { id } = useParams();
    let navigate = useNavigate();
    const initialTaskState = {
        taskId: null,
        familyId: "",
        userId: "",
        ownerId: "",
        title: "",
        description: "",
        completed: false,
        dueDate: new Date()
    };
    const [currentTask, setCurrentTask] = useState(initialTaskState);
    const [message, setMessage] = useState("");
    const [members, setMembers] = useState([]);
    const [owners, setOwners] = useState([]);
    const [currentMember, setCurrentMember] = useState([]);
    const [currentOwner, setCurrentOwner] = useState([]);
    const [currentMemberIndex, setCurrentMemberIndex] = useState(-1);
    const [currentOwnerIndex, setCurrentOwnerIndex] = useState(-1);
    const [change, setChange] = useState(false);
    const [descLengthReady, setDescLengthReady] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);
    const [noDueDate, setNoDueDate] = useState(true);

    const [showDeleteTask, setDeleteTask] = useState(false);
    const handleDeleteTaskOpen = () => setDeleteTask(true);
    const handleDeleteTaskClose = () => setDeleteTask(false);
    const [showMarkComplete, setMarkComplete] = useState(false);
    const handleMarkCompleteOpen = () => setMarkComplete(true);
    const handleMarkCompleteClose = () => setMarkComplete(false);
    const [showMarkIncomplete, setMarkIncomplete] = useState(false);
    const handleMarkIncompleteOpen = () => setMarkIncomplete(true);
    const handleMarkIncompleteClose = () => setMarkIncomplete(false);
    const [showSaveChanges, setSaveChanges] = useState(false);
    const handleSaveChangesOpen = () => setSaveChanges(true);
    const handleSaveChangesClose = () => setSaveChanges(false);

    // Retrieve Members
    useEffect(() => {
        retrieveMembers();
    }, []);

    useEffect(() => {
        if (id)
            getTask(id);
    }, [id]);

    useEffect(() => {
        handleSaveReady();
    });

    const retrieveMembers = () => {
        FamilyService.listMembers()
            .then(response => {
                setMembers(response.data);
                setOwners(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const setBox = event => {
        if (currentTask.dueDate === null) {
            let noDueDate = false;
            setNoDueDate(noDueDate);
            setCurrentTask({ ...currentTask, dueDate: new Date().toISOString().split("T")[0] });
            setChange(true);
        } else {
            let noDueDate = true;
            setNoDueDate(noDueDate);
            setCurrentTask({ ...currentTask, dueDate: null });
            setChange(true);
        }
    };

    const setActiveMember = (member, index) => {
        setCurrentMember(member);
        setCurrentMemberIndex(index);
        setChange(true);
    };

    const setActiveOwner = (owner, index) => {
        setCurrentOwner(owner);
        setCurrentOwnerIndex(index);
        setChange(true);
    };

    const getTask = taskId => {
        TaskService.get(taskId)
            .then(response => {
                setCurrentTask(response.data);
                setCurrentMember({ ...currentMember, userId: response.data.ownerId });
                setCurrentOwner({ ...currentOwner, userId: response.data.userId });
                if (currentTask.dueDate === null) {
                    setNoDueDate(true);
                }
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentTask({ ...currentTask, [name]: value });
        setChange(true);
    };

    const handleSaveReady = () => {
        if (currentTask.description.length < 254) {
            setDescLengthReady(true);
        }
        if (currentTask.description.length > 254) {
            setDescLengthReady(false);
        }
        if (change === true) {
            setChangeStatus(true);
        }
    };

    const updateTask = (status) => {
        var data = {
            taskId: currentTask.taskId,
            title: currentTask.title,
            ownerId: currentMember.userId,
            userId: currentOwner.userId,
            description: currentTask.description,
            completed: currentTask.completed,
            dueDate: currentTask.dueDate
        };
        handleSaveChangesClose();
        TaskService.update(currentTask.taskId, data)
            .then(response => {
                console.log(response.data);
                setMessage("The task was updated successfully!");
                navigate(-1);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteTask = () => {
        TaskService.remove(currentTask.taskId)
            .then(response => {
                console.log(response.data);
                navigate("/tasks");
            })
            .catch(e => {
                console.log(e);
            });
    };

    const markCompleted = () => {
        handleMarkCompleteClose();
        TaskService.markComplete(currentTask.taskId)
            .then(response => {
                navigate("/tasks");
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const markIncompleted = () => {
        handleMarkIncompleteClose();
        TaskService.markIncomplete(currentTask.taskId)
            .then(response => {
                navigate("/tasks");
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (

        <div className="list row">
            <div className="container"
                style={{
                    "height": "795px",
                    "overflow-y": 'scroll'
                }}
            >
                <FadeIn>
                    <h4>Edit Task</h4>
                    {currentTask.title ? (
                        <div className="edit-form">

                            <form>
                                <div className="form-group">
                                    <label htmlFor="title" class="form-label">
                                        <strong>Title</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={currentTask.title}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description" class="form-label">
                                        <strong>Description</strong>
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        rows="4"
                                        id="description"
                                        name="description"
                                        value={currentTask.description}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                {currentTask.description.length > 254 ?
                                    <div className="alert alert-danger">Description cannot exceed 255 characters!</div>
                                    :
                                    null
                                }
                                <ul className="list-group">
                                    <label htmlFor="owner" className="form-label">
                                        <strong>Task Owner: </strong>
                                    </label>
                                    {members &&
                                        members.map((owner, index) => (
                                            <li
                                                className={
                                                    "list-group-item " + (owner.userId == currentOwner.userId ? "active" : "")
                                                }
                                                onClick={() => setActiveOwner(owner, index)}
                                                onChange={handleInputChange}
                                                key={index}
                                            >
                                                {owner.mediaLink.length > 77 ?
                                                    <img className="img me-2" src={owner.mediaLink} alt={owner.mediaLink} height="33px" />
                                                    :
                                                    <Avatar className="me-2" name={owner.firstname + " " + owner.lastname} color="gray" round={true} size="33px" textSizeRatio={1.75} />
                                                }
                                                {owner.firstname} {owner.lastname}
                                            </li>
                                        ))}
                                    <i style={{
                                        "font-size": "12px"
                                    }}>Select other name to change owner.</i>
                                </ul>
                                <ul className="list-group">
                                    <label htmlFor="owner" className="form-label">
                                        <strong>Assigned To: </strong>
                                    </label>
                                    {members &&
                                        members.map((member, index) => (
                                            <li
                                                className={
                                                    "list-group-item " + (member.userId == currentMember.userId ? "active" : "")
                                                }
                                                onClick={() => setActiveMember(member, index)}
                                                onChange={handleInputChange}
                                                key={index}
                                            >
                                                {member.mediaLink.length > 77 ?
                                                    <img className="img me-2" src={member.mediaLink} alt={member.mediaLink} height="33px" />
                                                    :
                                                    <Avatar className="me-2" name={member.firstname + " " + member.lastname} color="gray" round={true} size="33px" textSizeRatio={1.75} />
                                                }
                                                {member.firstname} {member.lastname}
                                            </li>
                                        ))}
                                    <i style={{
                                        "font-size": "12px"
                                    }}>Select other name to change task assignment.</i>
                                </ul>

                                <div className="form-group" >
                                    <div className="row mt-1">
                                        <div className="col-1">
                                            {currentTask.dueDate === null ?
                                                <input className="form-check-input me-2" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                    onClick={setBox} checked
                                                />
                                                :
                                                <div>
                                                    {noDueDate === true ?
                                                        <input className="form-check-input me-2" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                            onClick={setBox}
                                                        />
                                                        :
                                                        <input className="form-check-input me-2" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                                            onClick={setBox}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="col">
                                            No Due Date
                                        </div>

                                    </div>
                                    <div className="form-label mb-3">
                                        <strong>Due Date</strong>
                                        {currentTask.dueDate != null ?
                                            <DatePicker
                                                onChange={date => handleInputChange({
                                                    target: {
                                                        value: date.toISOString().split("T")[0],
                                                        name: 'dueDate'
                                                    }
                                                })}
                                                name="dueDate"
                                                dateFormat="yyyy-MM-dd"
                                                value={currentTask.dueDate.toString().split("T")[0]}
                                                popperPlacement="top-start"
                                            />
                                            :
                                            <div>
                                                {noDueDate === 1 ?
                                                    <DatePicker
                                                        onChange={date => handleInputChange({
                                                            target: {
                                                                value: date.toISOString().split("T")[0],
                                                                name: 'dueDate'
                                                            }
                                                        })}
                                                        name="dueDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        //value={currentTask.dueDate.toString().split("T")[0]}
                                                        popperPlacement="top-start"
                                                        disabled
                                                    />
                                                    :
                                                    <DatePicker
                                                        onChange={date => handleInputChange({
                                                            target: {
                                                                value: date.toISOString().split("T")[0],
                                                                name: 'dueDate'
                                                            }
                                                        })}
                                                        name="dueDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        //value={currentTask.dueDate.toString().split("T")[0]}
                                                        popperPlacement="top-start"
                                                        disabled
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </form>
                            <div className="container-fluid" style={{
                                "justify-content": 'center'
                            }}>
                                <div style={{ "width": "100%" }} classname="row">
                                    <ul className="d-flex justify-content-between" style={{
                                        "font-size": '12px',
                                        "padding-inline-start": "0px"
                                    }}>
                                        <button
                                            type="submit"
                                            className={"btn mb-5 " + (((descLengthReady === true) && changeStatus === true) === true ? "btn-primary" : "btn-outline-primary disabled")}
                                            onClick={handleSaveChangesOpen}
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem"
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
                                                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                                            </svg>
                                            <div style={{
                                                "fontSize": "10px"
                                            }}>
                                                <strong>Save</strong>
                                            </div>
                                        </button>
                                        {currentTask.completed === 0 ?
                                            <button
                                                className="btn btn-success mb-5 ms-2"
                                                onClick={handleMarkCompleteOpen}
                                                style={{
                                                    "--bs-btn-line-height": "1.3",
                                                    "--bs-btn-border-radius": "4rem",
                                                    "--bs-btn-padding-x": "0.95rem"
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16">
                                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                    <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                </svg>
                                                <div style={{
                                                    "fontSize": "10px"
                                                }}>
                                                    <strong>Done!</strong>
                                                </div>
                                            </button>
                                            :
                                            <button
                                                className="btn btn-outline-danger mb-5 ms-2"
                                                onClick={handleMarkIncompleteOpen}
                                                style={{
                                                    "--bs-btn-line-height": "1.3",
                                                    "--bs-btn-border-radius": "4rem",
                                                    "--bs-btn-padding-x": "0.95rem"
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-exclamation-square" viewBox="0 0 16 16">
                                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                                </svg>
                                                <div style={{
                                                    "fontSize": "10px"
                                                }}>
                                                    <strong>Not Done?</strong>
                                                </div>
                                            </button>
                                        }
                                        <button
                                            className="btn btn-danger mb-5 ms-2"
                                            onClick={handleDeleteTaskOpen}
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem"
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                            <div style={{
                                                "fontSize": "10px"
                                            }}>
                                                <strong>Delete</strong>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-secondary mb-5 ms-2"
                                            style={{
                                                "--bs-btn-line-height": "1.3",
                                                "--bs-btn-border-radius": "4rem",
                                                "--bs-btn-padding-x": "0.95rem"
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                                <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                                                <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                                            </svg>
                                            <div style={{
                                                "fontSize": "10px"
                                            }}>
                                                <Link to={"/tasks"} className="nav-link">
                                                    <strong>Cancel</strong>
                                                </Link>
                                            </div>
                                        </button>
                                    </ul>
                                </div>
                            </div>
                            <p>{message}</p>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <div className="text-center">Task not found!
                                <button className="btn btn-secondary text-center ms-2">
                                    <Link to={"/tasks"} className="nav-link">
                                        Back
                                    </Link>
                                </button>
                            </div>
                        </div>
                    )}
                </FadeIn>
            </div>
            <Modal centered show={showDeleteTask} onHide={handleDeleteTaskClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Task?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Are you sure you want to delete this task?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteTask}>
                        Delete!
                    </Button>
                    <Button variant="secondary" onClick={handleDeleteTaskClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showMarkComplete} onHide={handleMarkCompleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Mark Completed?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Do you want to mark this task as completed?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={markCompleted}>
                        Yes, Complete!
                    </Button>
                    <Button variant="secondary" onClick={handleMarkCompleteClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showMarkIncomplete} onHide={handleMarkIncompleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Mark Incomplete?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>This task has already been marked as completed! Do you want to mark this task as incomplete?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={markIncompleted}>
                        Yes, mark incomplete.
                    </Button>
                    <Button variant="secondary" onClick={handleMarkIncompleteClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showSaveChanges} onHide={handleSaveChangesClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Changes?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="success" onClick={updateTask}>
                        Yes, Save!
                    </Button>
                    <Button variant="secondary" onClick={handleSaveChangesClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
};

export default TaskEdit;
