import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import FadeIn from "react-fade-in";
import FamilyService from "../services/family.service";
import {Link, useNavigate} from 'react-router-dom';

const vfirstname = (value) => {
    if (value.length < 1 || value.length > 30) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vlastname = (value) => {
    if (value.length < 1 || value.length > 30) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const UpdateInfo = () => {
    const form = useRef();
    const checkBtn = useRef();
    let navigate = useNavigate();
    const initialUserState = {
        firstname: "",
        lastname: "",
        email: ""
    };
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    var currentUser = AuthService.getCurrentUser();
    const [firstname, setFirstName] = useState(currentUser.firstname);
    const [lastname, setLastName] = useState(currentUser.lastname);
    const [email, setEmail] = useState(currentUser.email);

    const onChangeFirstname = (e) => {
        const firstname = e.target.value;
        setFirstName(firstname);
    };

    const onChangeLastName = (e) => {
        const lastname = e.target.value;
        setLastName(lastname);
    };

    const handleChangeInfo = (e) => {
        var userId = currentUser.userId;

        e.preventDefault();

        setMessage("");
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            FamilyService.update(firstname, lastname).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                    AuthService.refresh(userId).then(
                        () => {
                            navigate("/profile");
                            window.location.reload();
                        },
                        (error) => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            setMessage(resMessage);
                        }
                    );
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );

        }
    };

    return (
        <div>
            <p/>
            <FadeIn>
                <div className="list row">
                    <div className="col-md-6">
                        <h3>Update your Personal Info</h3>
                        <FadeIn>
                            <Form onSubmit={handleChangeInfo} ref={form}>
                                {!successful && (
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="firstname">First Name</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="firstname"
                                                value={firstname}
                                                onChange={onChangeFirstname}
                                                validations={[vfirstname]}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="lastname">Last Name</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                name="lastname"
                                                value={lastname}
                                                onChange={onChangeLastName}
                                                validations={[vlastname]}
                                            />
                                        </div>
                                        <p/>
                                        <div className="form-group">
                                            <button className="btn btn-success btn-block">
                                                Change Personal Info
                                            </button>
                                            <button className="btn btn-secondary ms-3">
                                                <Link to={"/profile"} className="nav-link">
                                                    Cancel
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <p/>
                                {message && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                successful ? "alert alert-success" : "alert alert-danger"
                                            }
                                            role="alert"
                                        >
                                            {message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                        </FadeIn>
                    </div>
                </div>
            </FadeIn>
        </div>
    );
};

export default UpdateInfo;