import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import TaskService from "../services/task.service";
import AuthService from "../services/auth.service";
import FadeIn from 'react-fade-in';
import RetrieveAll from "../components/RetrieveAll";
import RetrieveIncomplete from "../components/RetrieveIncomplete";

const TasksList = props => {
    let navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    const [tasks, setTasks] = useState([]);
    const [currentTask, setCurrentTask] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [showComplete, setShowComplete] = useState(false);
    const [tasksCount, setTasksCount] = useState(0);
    const [count, setCount] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!currentUser) {
            navigate("/login")
        } else {
            AuthService.refresh(currentUser.userId);
            if (currentUser.familyId === null) {
                navigate("/needfamily")
            }
        }
        getTaskCount();
    }, []);

    const retrieveAll = () => {
        TaskService.getAll()
            .then(response => {
                setTasks(response.data);
                setTasksCount(response.data);

                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const retrieveIncomplete = () => {
        setLoading(true);
        TaskService.getIncomplete()
            .then(response => {
                setTasks(response.data);
                console.log(response.data);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
            });
    };

    const getTaskCount = () => {
        TaskService.getTasksCount()
            .then(response => {
                setCount(response.data)
                console.log(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    };

    const taskCount = count.map(
        (element) => {
            return (
                <div>
                    <div>{element.task_count}/40</div>
                </div>
            )
        }
    );

    const setSwitch = () => {
        if (showComplete === true) {
            setShowComplete(false);
            retrieveIncomplete()
        } else {
            setShowComplete(true);
            retrieveAll()
        }
    };

    return (
        <div className="container-flex">
            <div className="list row">
                <div className="col">
                    <FadeIn>
                        <h4>Tasks List</h4>
                        <div className="row row-cols-2">
                            <div className="col">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                        onClick={() => setSwitch()}
                                    />
                                    <div className="
                                    form-check-label" htmlFor="flexSwitchCheckDefault" style={{
                                            "font-size": '12px'
                                        }}>
                                        Show Completed Tasks
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 align-right">
                                {taskCount === 40 ?
                                    <div className="me-4" style={{
                                        "color": 'red'
                                    }}>40/40</div>
                                    :
                                    <div className="me-4">{taskCount}</div>
                                }
                            </div>
                        </div>
                        <div className="container-flex"
                            style={{
                                "overflow-x": 'scroll'
                            }}
                        >
                            {loading ?
                                <div>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <div>
                                    {showComplete === true ?
                                        <FadeIn>
                                            <RetrieveAll />
                                        </FadeIn>
                                        :
                                        <FadeIn>
                                            <RetrieveIncomplete />
                                        </FadeIn>
                                    }
                                </div>
                            }
                        </div>
                    </FadeIn>
                </div>
                <p />
                <p />
                <p />
                <p />
                <p />
            </div>
        </div>
    );
};

export default TasksList;