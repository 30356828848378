import React, { useState, useRef } from "react";
import fileUploadService from "../services/file-upload.service";
import AuthService from "../services/auth.service";
import AvatarEditor from "react-avatar-editor";
import FadeIn from "react-fade-in";
import { Link, useNavigate } from "react-router-dom";
import familyService from "../services/family.service";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ImageUpload = () => {
    const currentUser = AuthService.getCurrentUser();
    const [currentFile, setCurrentFile] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const editor = useRef(null);
    let navigate = useNavigate();

    const [showSkipPhoto, setShowSkipPhoto] = useState(false);
    const handleSkipPhotoOpen = () => setShowSkipPhoto(true);
    const handleSkipPhotoClose = () => setShowSkipPhoto(false);

    const selectFile = (event) => {
        setCurrentFile(event.target.files[0]);
        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        setProgress(0);
        setMessage("");
    };

    const cancel = () => {
        handleSkipPhotoClose();
        familyService.changeFirstLogin(currentUser.userId)
            .then(() => {
                AuthService.refresh(currentUser.userId).then(
                    () => {
                        navigate("/profile");
                        window.location.reload();
                    }
                );
            })
    }

    const upload = async (canvas) => {
        setProgress(0);

        fileUploadService.upload(currentUser.userId, canvas, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((response) => {
                setMessage(response.data.message);
                navigate("/profile");
                window.location.reload();
            })
            .catch((err) => {
                setProgress(0);

                if (err.response && err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                } else {
                    setMessage("Could not upload the Image!");
                }

                setCurrentFile(undefined);
            });
    };

    return (
        <div>
            <FadeIn>
                <p />
                <div className="list row">
                    <div className="col-md-6">
                        <h3>Choose Profile Photo</h3>
                        <span>
                            Press Choose File to select your Honee-Do profile photo. Drag and drop in the circle to crop the photo
                            - then hit Save Photo down below when ready!
                        </span>
                        <div className="row">
                            <div className="col-8">
                                <label className="btn btn-default p-0">
                                    <input type="file" accept="image/*" onChange={selectFile} />
                                </label>
                                <p />
                                {previewImage && (
                                    <div>
                                        <AvatarEditor
                                            ref={editor}
                                            image={previewImage}
                                            width={250}
                                            height={250}
                                            border={50}
                                            color={[225, 225, 225, 0.9]} // RGBA
                                            scale={1.2}
                                            rotate={0}
                                            borderRadius={200}
                                        />
                                        <button className="btn btn-success"
                                            disabled={!currentFile}
                                            onClick={() => {
                                                if (editor) {
                                                    const canvas = editor.current.getImage();
                                                    canvas.toBlob((blob) => {
                                                        upload(blob)
                                                    }, 'image/jpeg', 0.95);
                                                }
                                            }}>Save Photo
                                        </button>
                                    </div>
                                )}
                                <p />
                                {currentUser.firstLogin === true ?
                                    <button className="btn btn-secondary"
                                    onClick={handleSkipPhotoOpen}
                                    >

                                        Cancel
                                    </button>
                                    :
                                    <button className="btn btn-secondary">
                                        <Link to={"/profile"} className="nav-link">
                                            Cancel
                                        </Link>
                                    </button>
                                }
                            </div>
                        </div>

                        {currentFile && (
                            <div className="progress my-3">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progress + "%" }}
                                >
                                    {progress}%
                                </div>
                            </div>
                        )}
                        {message && (
                            <div className="alert alert-secondary mt-3" role="alert">
                                {message}
                            </div>
                        )}
                    </div>
                </div>
            </FadeIn>
            <Modal centered show={showSkipPhoto} onHide={handleSkipPhotoClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Skip adding Profile Photo?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Are you sure you want to skip adding a profile photo? It makes
                        you easier to identify (and it looks nice!) You can come back
                        and add a photo later in your profile settings if you skip now.</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSkipPhotoClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={cancel}>
                            Yes, leave!
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ImageUpload;