import api from './api';

const getAll = () => {
  return api.get(`/tasks/all`);
};

const getIncomplete = () => {
  return api.get(`/tasks`);
};

const getTasksCount = () => {
  return api.get(`/tasks/count/all`);
};

const getNotifications = () => {
  return api.get(`/tasks/notifications/all`);
};

const get = taskId => {
  return api.get(`/tasks/${taskId}`);
};

const create = data => {
  return api.post("/tasks", data);
};

const markAsSeen = () => {
  return api.put("/tasks/notifications/seen");
};

const update = (taskId, data) => {
  return api.put(`/tasks/${taskId}`, data);
};

const sendUserUpdate = (taskId, data) => {
  return api.put(`/tasks/update/user/${taskId}`, data);
};

const sendOwnerUpdate = (taskId, data) => {
  return api.put(`/tasks/update/owner/${taskId}`, data);
};

const sendReminder = taskId => {
  return api.put(`/tasks/reminder/${taskId}`);
};

const markComplete = (taskId) => {
  return api.put(`/tasks/update/complete/${taskId}`);
};

const markIncomplete = (taskId) => {
  return api.put(`/tasks/update/incomplete/${taskId}`);
};

const getAllUpdates = taskId => {
  return api.get(`/tasks/update/${taskId}`);
};


const remove = taskId => {
  return api.delete(`/tasks/${taskId}`);
};

const removeAllCompleted = familyId => {
  return api.delete(`/tasks/completed/${familyId}`);
};

const removeAll = () => {
  return api.delete(`/tasks`);
};

const findByTitle = title => {
  return api.get(`/tasks?title=${title}`);
};

const TaskService = {
  getAll,
  getIncomplete,
  getTasksCount,
  getNotifications,
  getAllUpdates,
  get,
  create,
  markAsSeen,
  update,
  remove,
  removeAllCompleted,
  removeAll,
  findByTitle,
  sendUserUpdate,
  sendOwnerUpdate,
  sendReminder,
  markComplete,
  markIncomplete
};

export default TaskService;
