import React, { useEffect, useState} from "react";
import {NavBar} from "./navigation/NavBar";
import { useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import FadeIn from "react-fade-in";
import familyService from "../services/family.service";
import {NavBarBottom} from "./navigation/NavBarBottom";
import { Capacitor } from '@capacitor/core';

export const PageLayout = ({children}) => {
    const [firstLogin, setFirstLogin] = useState();
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState("");
    const currentUser = AuthService.getCurrentUser();
    let navigate = useNavigate();

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            setPlatform("native");
            console.info('Native platform detected!');
        } else {
            setPlatform("web");
          console.info('Web platform detected!');
        }
        if (!currentUser) {
            navigate("/login");
        }
      }, []);

    //useEffect(() => {
    //    setLoading(true);
    //    if (!currentUser) {
    //       navigate("/login");
    //       //window.location.reload();
    //    }
    //    firstTime(currentUser);
    //    setLoading(false);
    //}, []);

    

    const firstTime = async () => {
        try {
            const res = await familyService.checkFirstLogin();
            setFirstLogin(res.data.data);
            if (res.data.data === 1) {
                navigate("/avatar");
            } else {
                const res = await familyService.getFamily(currentUser.userId);
                if (res.data.length === 0) {
                    navigate("/needfamily");
                }
            }
        } catch (e) {
            console.log(e);
        }
        //setLoading(false);
    };

    return (
        <div className="page-layout">
            <div>
                <NavBar/>
                    <FadeIn>
                        <div className="container mt-1">
                            <div className="text-lg-center">
                                <div className="page-layout__content">{children}</div>
                            </div>
                        </div>
                    </FadeIn>
                {loading === true ?
                    null
                :
                    <footer className="footer" style={{
                        "overflow-anchor": 'none',
                        //"box-shadow": "0px -1px 20px rgb(0 0 0 / 5)",
                        "z-index": "999"
                        }}>
                        <NavBarBottom/>
                    </footer>
                }
            </div>
        </div>
    );
};