import React, { useState, useEffect } from "react";
import FamilyService from "../services/family.service";
import AuthService from "../services/auth.service";
import FadeIn from 'react-fade-in';
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const ListMembers = () => {
    const currentUser = AuthService.getCurrentUser();
    const [members, setMembers] = useState([]);
    const [currentMember, setCurrentMember] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [family, setFamily] = useState([]);
    let navigate = useNavigate();

    const [showAdminOnlyInvite, setShowAdminOnlyInvite] = useState(false);
    const handleAdminOnlyInviteOpen = () => setShowAdminOnlyInvite(true);
    const handleAdminOnlyInviteClose = () => setShowAdminOnlyInvite(false);
    const [showLeaveFamily, setShowLeaveFamily] = useState(false);
    const handleLeaveFamilyOpen = () => setShowLeaveFamily(true);
    const handleLeaveFamilyClose = () => setShowLeaveFamily(false);
    const [showRemoveMember, setShowRemoveMember] = useState(false);
    const handleRemoveMemberOpen = () => setShowRemoveMember(true);
    const handleRemoveMemberClose = () => setShowRemoveMember(false);

    useEffect(() => {
        retrieveMembers();
        getFamily();
    }, []);

    const retrieveMembers = () => {
        FamilyService.listMembers()
            .then(response => {
                setMembers(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getFamily = () => {
        FamilyService.getFamily(currentUser.userId)
            .then(response => {
                setFamily(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const setActiveMember = (member, index) => {
        if (currentIndex === index) {
            setCurrentMember(null)
            setCurrentIndex(-1)
        } else {
            setCurrentMember(member);
            setCurrentIndex(index);
        }
    };

    const refreshList = () => {
        retrieveMembers();
        setCurrentMember(null);
        setCurrentIndex(-1);
    };

    const removeMember = () => {
        FamilyService.remove(currentMember.userId)
            .then(response => {
                console.log(response.data);
                refreshList();
                navigate("/family");
            })
            .catch(e => {
                console.log(e);
            });
    };

    const leaveFamily = () => {
        FamilyService.leave()
            .then(response => {
                console.log(response.data);
                AuthService.logout();
                AuthService.refresh();
                window.location.reload();
                navigate("/login");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div className="list row">
            <div className="col-md-6">
                {family &&
                    <div>
                        <FadeIn>
                            <h4>The {family.familyName} Family</h4>
                            <p />
                            <div className="container"
                            >
                                <div className="scrollable"
                                    style={{
                                        "overflow-y": 'scroll',
                                    }}
                                >
                                    <div className="accordion" id="accordionFamily">
                                        {members &&
                                            members.map((member, index) => (
                                                <div key={"a" + index} className="accordion-item">
                                                    <h2 className="accordion-header" id={"a" + index}>
                                                        <button
                                                            className={"accordion-button" + (index === currentIndex ? "" : " collapsed")}
                                                            type="button"
                                                            aria-expanded={(index === currentIndex ? "true" : "false")}
                                                            aria-controls={"a" + index}
                                                            onClick={() => setActiveMember(member, index)}
                                                        >
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col text-truncate">
                                                                        {member.mediaLink.length > 77 ?
                                                                            <img className="img me-2" src={member.mediaLink} alt={member.mediaLink} height="30px" />
                                                                            :
                                                                            <Avatar className="me-2" name={member.firstname + " " + member.lastname} color="gray" round={true} size="25" textSizeRatio={1.50} />
                                                                        }
                                                                        {member.firstname} {member.lastname}
                                                                    </div>
                                                                    {member.roleId === 2 &&
                                                                        <div className="col-3 text-end">
                                                                            <div className="badge text-bg-primary mt-1">Admin</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id={"a" + currentIndex}
                                                        className={"accordion-collapse collapse" + (index === currentIndex ? " show" : "")}
                                                        aria-labelledby={"a" + currentIndex} data-bs-parent={"a" + currentIndex}>
                                                        <div className="accordion-body">
                                                            {currentUser.roles == "ROLE_ADMIN" ? (
                                                                <div>
                                                                    {member.roleId === 2 ?
                                                                        <button className="btn btn-warning mr-2"
                                                                            style={{
                                                                                "--bs-btn-line-height": "1.3",
                                                                                "--bs-btn-border-radius": "4rem",
                                                                                "--bs-btn-padding-x": "0.95rem",
                                                                                "fontSize": "10px"
                                                                            }}>
                                                                            <Link to={"/family/reassign_admin"} className="nav-link">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-down me-2" viewBox="0 0 16 16">
                                                                                    <path d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Zm.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V10.5a.5.5 0 0 0-1 0v2.793l-.646-.647a.5.5 0 0 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                                                                    <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                                                                </svg>
                                                                                <strong>Reassign Admin</strong>
                                                                            </Link>
                                                                        </button>
                                                                        :
                                                                        <button className="btn btn-warning mr-2"
                                                                            onClick={handleRemoveMemberOpen}
                                                                            style={{
                                                                                "--bs-btn-line-height": "1.3",
                                                                                "--bs-btn-border-radius": "4rem",
                                                                                "--bs-btn-padding-x": "0.95rem",
                                                                                "fontSize": "10px"
                                                                            }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-dash me-2" viewBox="0 0 16 16">
                                                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1Zm0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                                                                <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                                                            </svg>
                                                                            <strong>Remove from Family</strong>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <p />
                                                                    {member.userId === currentUser.userId ?

                                                                        <button className="btn btn-danger"
                                                                            onClick={handleLeaveFamilyOpen}
                                                                            style={{
                                                                                "--bs-btn-line-height": "1.3",
                                                                                "--bs-btn-border-radius": "4rem",
                                                                                "--bs-btn-padding-x": "0.95rem",
                                                                                "fontSize": "10px"
                                                                            }}>

                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-x-fill me-2" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                                                                            </svg>
                                                                            <strong>Leave Family</strong>

                                                                        </button>
                                                                        :
                                                                        <button className="btn btn-secondary"
                                                                            onClick={handleRemoveMemberOpen}
                                                                            style={{
                                                                                "--bs-btn-line-height": "1.3",
                                                                                "--bs-btn-border-radius": "4rem",
                                                                                "--bs-btn-padding-x": "0.95rem",
                                                                                "fontSize": "10px"
                                                                            }}>

                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-x-fill me-2" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                                                                            </svg>
                                                                            <strong>Remove Member</strong>

                                                                        </button>
                                                                    }
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                        <Modal centered show={showRemoveMember} onHide={handleRemoveMemberClose}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Remove {member.firstname}?</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <span>Are you sure you want to remove {member.firstname} from the
                                                                    family? Their current tasks will stay with the family but
                                                                    will need to be reassigned to other members.</span>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleLeaveFamilyClose}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="danger" onClick={handleLeaveFamilyClose}>
                                                                    <Link to={"/needfamily"} className="nav-link">
                                                                        Yes, leave!
                                                                    </Link>
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                        <Modal centered show={showLeaveFamily} onHide={handleLeaveFamilyClose}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Leave the {family.familyName + " "}Family?</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <span>Are you sure you want to leave the {family.familyName + " "}
                                                                    family? Your created tasks will remain with this family
                                                                    and you will need to either join another family or
                                                                    create a new one to keep using Honee-Do.</span>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleLeaveFamilyClose}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="danger" onClick={handleLeaveFamilyClose}>
                                                                    <Link to={"/needfamily"} className="nav-link">
                                                                        Yes, leave!
                                                                    </Link>
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <p />
                                <div className="container-fluid" style={{
                                    "justify-content": 'center'
                                }}>
                                    <div style={{ "width": "100%" }} classname="row">
                                        <ul className="d-flex justify-content-center" style={{
                                            "font-size": '12px',
                                            "padding-inline-start": "0px"
                                        }}>
                                            {currentUser.roles == "ROLE_ADMIN" ? (
                                                <div className="mt-2">
                                                    <button
                                                        className="btn btn-success"
                                                        style={{
                                                            "--bs-btn-line-height": "1.3",
                                                            "--bs-btn-border-radius": "4rem",
                                                            "--bs-btn-padding-x": "0.95rem"
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                        </svg>
                                                        <div style={{
                                                            "fontSize": "10px"
                                                        }}>
                                                            <Link to={"/invite"} className="nav-link">
                                                                <div><strong>Invite</strong></div>
                                                                <strong>Member</strong>
                                                            </Link>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="btn btn-danger ms-2"
                                                        style={{
                                                            "--bs-btn-line-height": "1.3",
                                                            "--bs-btn-border-radius": "4rem",
                                                            "--bs-btn-padding-x": "0.95rem"
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                                        </svg>
                                                        <div style={{
                                                            "fontSize": "10px"
                                                        }}>
                                                            <Link to={"/family/delete"} className="nav-link">
                                                                <div><strong>Delete</strong></div>
                                                                <strong>Family</strong>
                                                            </Link>
                                                        </div>
                                                    </button>
                                                </div>
                                            ) : (
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={handleAdminOnlyInviteOpen}
                                                    style={{
                                                        "--bs-btn-line-height": "1.3",
                                                        "--bs-btn-border-radius": "4rem",
                                                        "--bs-btn-padding-x": "0.95rem"
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                    </svg>
                                                    <div style={{
                                                        "fontSize": "10px"
                                                    }}>

                                                        <div><strong>Invite</strong></div>
                                                        <strong>Member</strong>

                                                    </div>
                                                </button>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </FadeIn>
                    </div>
                }
                <p />
                <Modal centered show={showAdminOnlyInvite} onHide={handleAdminOnlyInviteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>You can't send Invites!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>Only the Family Admin is allowed to send invites for new family members. If you think someone should be invited,
                            ask your Family Admin to send them an invite!</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAdminOnlyInviteClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};