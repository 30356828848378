import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import FadeIn from "react-fade-in";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const Login = () => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const currentUser = AuthService.getCurrentUser();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (currentUser) {
      navigate('/tasks');
    }
  }, []);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password).then(
        () => {
          navigate("/tasks");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
      <div className="container-fluid" style={{
        "max-width": '300px'
      }}>
        <p/>
        <p/>
        <FadeIn>
          <h1 className="mt-5" style={{
            "font-size": '50px',
            "color": '#5C80BC',
            "font-family": 'Pacifico',
          }}
          >
            <center>
              Honee-Do!
            </center>
          </h1>
          <p />
          <FadeIn>
            <Form onSubmit={handleLogin} ref={form}>
              <div className="form-group">
                <label htmlFor="email"
                  style={{
                    //"color": 'white',
                  }}
                >
                  Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password"
                  style={{
                    //"color": 'white',
                  }}
                >
                  Password
                </label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                />
              </div>
              <p />

              <div className="container-fluid">
                <div className="form-group d-grid gap-2">
                  <button className="btn btn-success btn-block" disabled={loading}>
                    {loading && (
                      <div className="spinner-border spinner-border-sm"></div>
                    )}
                    <div>
                      Login
                    </div>
                  </button>
                  <button className="btn btn-secondary btn-block">
                    <Link to={"/forgot"} className="nav-link">
                      Forgot Password?
                    </Link>
                  </button>
                </div>
              </div>
              <p />
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </FadeIn>
        </FadeIn>
        <div>
          <span className="position-absolute start-50 translate-middle mt-2" style={{
            "color": 'white',
            "font-size": '18px'
          }}>
            New to Honee-Do?
          </span>
          <p />
          <div className="mt-5 position-absolute start-50 translate-middle">
            <button className="btn btn-primary mt-5">
              <Link to={"/verify"} className="nav-link"
                style={{
                  "color": 'white'
                }}
              >
                Sign Up!
              </Link>
            </button>
            <div>
              <Link to={"/privacy"} className="nav-link mt-3"
                style={{
                  "color": 'white'
                }}
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Login;