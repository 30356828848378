import React, { useEffect, useState } from "react";
import { Toast, ToastContainer } from 'react-bootstrap';
import { Routes, Route, useNavigate } from "react-router-dom";
import AddTask from "./pages/AddTask";
import TaskEdit from "./components/TaskEdit";
import TaskView from "./components/TaskView";
import TasksList from "./pages/TasksList";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import { MyFamily } from "./pages/MyFamily";
import { CreateFamily } from "./pages/CreateFamily";
import { ReassignAdmin } from "./components/ReassignAdmin";
import { Invite } from "./pages/Invite";
import { Join } from "./pages/Join";
import UpdateInfo from "./pages/UpdateInfo";
import { Privacy } from "./pages/Privacy";
import { DeleteFamily } from "./pages/DeleteFamily";
import ChangeForgotPassword from "./pages/ChangeForgotPassword";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import { NoFamily } from "./components/NoFamily";
import FileUpload from "./components/FileUpload";
import { PushNotifications } from '@capacitor/push-notifications';
import AuthService from "./services/auth.service";
import { Capacitor } from '@capacitor/core';
import { requestForToken } from "./firebase";
import { onMessageListener } from "./firebase";
import { Request } from "./pages/Request"
import { AppUrlListener } from "./components/AppURLlistener";
import { PageLayout } from "./components/PageLayout";
import VerifyEmail from "./pages/VerifyEmail";

const App = () => {
  let navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      addListeners();
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        } else {
          console.info('Notifications not allowed!')
        }
      })
      PushNotifications.register();
      console.info('Native platform detected!');
    } else {
      requestForToken(setTokenFound);
      console.info('Web platform detected!');
    }
  }, []);

  const handleDeepLink = (event) => {
    console.log('Deep link URL:', event.url);
    navigate(event.url);
    //if (event.url.includes('example.com/my-screen')) {
    // Perform navigation logic here
    // Example: history.push('/my-screen');
    //}
  };

  const addListeners = async () => {
    await PushNotifications.addListener('registration', token => {
      console.info('Registration token: ', token.value);
      if (currentUser) {
        AuthService.sendFirebaseToken(currentUser.userId, token.value)
          .then(response => {
            console.log(response.data)
          })
          .catch(e => {
            console.log(e);
          });
      }
    });

    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      setShow(true);
      setNotification({ title: notification.title, body: notification.body })
      console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });

    await App.addListener('appUrlOpen', handleDeepLink);
  }

  onMessageListener().then(payload => {
    let userId = payload.data.userId;
    if (userId == currentUser.userId) {
      setShow(true);
      setNotification({ title: payload.notification.title, body: payload.notification.body })
      console.log(payload);
    }
    console.log(payload);
  }).catch(err => console.log('failed: ', err));



  return (
    <div>
      <ToastContainer className="p-3" position="top-end">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide animation
          style={{
            position: 'absolute',
            top: 20,
            right: 20,
            minWidth: 200
          }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
      </ToastContainer>
      <AppUrlListener></AppUrlListener>
      <PageLayout/>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register/:token" element={<Register />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/forgot/:token" element={<ChangeForgotPassword />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/" element={<TasksList />} />
        <Route path="/tasks" element={<TasksList />} />
        <Route path="/add" element={<AddTask />} />
        <Route path="/tasks/view/:id" element={<TaskView />} />
        <Route path="/tasks/edit/:id" element={<TaskEdit />} />
        <Route path="/request/:token" element={<Request />} />
        <Route path="/join/:token" element={<Join />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/needfamily" element={<NoFamily />} />
        <Route path="/update" element={<UpdateInfo />} />
        <Route path="/change" element={<ChangePassword />} />
        <Route path="/family" element={<MyFamily />} />
        <Route path="/family/create" element={<CreateFamily />} />
        <Route path="/family/delete" element={<DeleteFamily />} />
        <Route path="/family/reassign_admin" element={<ReassignAdmin />} />
        <Route path="/avatar" element={<FileUpload />} />
      </Routes>
    </div>
  );
}

export default App;
