import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import FamilyService from "../services/family.service";
import FadeIn from "react-fade-in";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";

export const DeleteFamily = () => {
    let navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    const form = useRef();
    const checkBtn = useRef();
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [family, setFamily] = useState([]);
    const [familyName, setFamilyName] = useState([]);
    const [change, setChange] = useState(false);

    const validFamilyName = (value) => {
        if (value === family.familyName) {
            return (
                <div className="alert alert-danger" role="alert">
                    This does not match your family name.
                </div>
            );
        }
    };

    useEffect(() => {
        getFamily();
    }, []);

    const getFamily = () => {
        FamilyService.getFamily(currentUser.userId)
            .then(response => {
                setFamily(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const onChangeFamilyName = (e) => {
        const familyName = e.target.value;
        setFamilyName(familyName);
        setChange(true);
    };

    const handleDeleteFamily = () => {
        var familyid = currentUser.familyId;
        var userid = currentUser.userId;

        setMessage("");
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            FamilyService.delete(familyid).then(
                (response) => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                    AuthService.refresh(userid).then(
                        () => {
                            navigate("/family");
                            window.location.reload();
                        },
                        (error) => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            setMessage(resMessage);
                        }
                    );
                    navigate("/family");
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );

        }
    };

    return (
        <div className="list row">
            <div className="col-md-6">
                <FadeIn>
                    <h4>Delete the {family.familyName} Family?</h4>
                    <p />
                    <Form onSubmit={handleDeleteFamily} ref={form}>
                        <div>
                            Deleting your family is a big deal!
                            You will lose ALL of the family's tasks and all members will be
                            removed, yourself included. This CANNOT be undone! You will need
                            to create or join another family to keep using Honee-Do.
                        </div>
                        <p />
                        <div>
                            If you're sure, type your family name below and hit the Delete button.
                            If you're not sure, hit the Cancel button.
                        </div>
                        <div>
                            <div className="form-group">
                                <label htmlFor="familyName">Enter the family name: "{family.familyName}"</label>
                                <p />
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="familyName"
                                    value={familyName}
                                    onChange={onChangeFamilyName}
                                    validations={[validFamilyName]}
                                />
                            </div>
                            <p />
                            <div className="form-group">
                                <button
                                    className={"btn btn-danger" + (family.familyName === familyName ? "" : " disabled")}
                                    onClick={handleDeleteFamily}
                                    style={{
                                        "--bs-btn-line-height": "1.3",
                                        "--bs-btn-border-radius": "4rem",
                                        "--bs-btn-padding-x": "0.95rem"
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                    </svg>
                                    <div style={{
                                        "fontSize": "10px"
                                    }}>
                                        <div><strong>Delete</strong></div>
                                        <strong>Family</strong>
                                    </div>
                                </button>
                                <button
                                    className="btn btn-secondary ms-2"
                                    style={{
                                        "--bs-btn-line-height": "1.7",
                                        "--bs-btn-border-radius": "4rem",
                                        "--bs-btn-padding-x": "0.95rem"
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                        <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                                        <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                                    </svg>
                                    <div style={{
                                        "fontSize": "10px"
                                    }}>
                                        <Link to={"/family"} className="nav-link">
                                            <strong>Cancel</strong>
                                        </Link>
                                    </div>
                                </button>
                            </div>
                            <p />
                        </div>


                        {message && (
                            <div className="form-group">
                                <div
                                    className={
                                        successful ? "alert alert-success" : "alert alert-danger"
                                    }
                                    role="alert"
                                >
                                    {message}
                                </div>
                            </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </FadeIn>

            </div>

        </div>
    );
};