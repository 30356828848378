import api from "./api"
import TokenService from "./token.service";

class AuthService {

  verify(email) {
    return api.post("/auth/verify",
        {email}
    );
  };
  
  // Sign up for account
  register(firstname, lastname, email, password) {
    return api.post("/auth/signup",
        {firstname, lastname, email, password}
    );
  };

  // Sign in to HoneeDo
  login(email, password) {
    return api.post("/auth/signin",
        {email, password})
        .then((response) => {
          if (response.data.accessToken) {
              TokenService.setUser(response.data);
          }
          return response.data;
        });
  };

  // Refresh user JSON
  refresh(userId) {
    return api.post("/auth/refresh",
        {userId})
        .then((response) => {
          if (response.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response.data))
          }

          return response.data;
        });
  };

  // Sign out of HoneeDo
    logout() {
        TokenService.removeUser();
    }

  // Get user JSON for logged in user
  getCurrentUser() {
    return TokenService.getUser();
  }

  // Sends firebase token to backend
  sendFirebaseToken(userId, firebaseToken) {
    return api.post(`/family/pushtoken/${userId}`,
        {firebaseToken});
  }

  // Checks firebase token for duplicate
  checkFirebaseToken(userId, firebaseToken) {
    return api.post(`/family/checktoken/${userId}`,
        {firebaseToken});
  }
}

export default new AuthService();