import React, { useRef, useState, useEffect } from "react";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import { isEmail } from "validator";
import FamilyService from "../services/family.service";
import FadeIn from "react-fade-in";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

export const Invite = () => {
    const currentUser = AuthService.getCurrentUser();
    const form = useRef();
    const checkBtn = useRef();
    const [email, setEmail] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [family, setFamily] = useState([]);
    const [change, setChange] = useState(false);
    const [loading, setLoading] = useState(false);

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
        setChange(true);
    };

    useEffect(() => {
        getFamily();
    }, []);

    const getFamily = () => {
        FamilyService.getFamily(currentUser.userId)
            .then(response => {
                setFamily(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleInvite = (e) => {

        e.preventDefault();

        setMessage("");
        setSuccessful(false);
        setLoading(true);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            FamilyService.invite(email).then(
                (response) => {
                    setMessage(response.data.message);
                    setLoading(false);
                    setSuccessful(true);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setLoading(false);
                    setSuccessful(false);
                }
            );
        }
    };

    return (
        <FadeIn>
            <div className="list row">
                <div className="col-md-6">
                    <h4>Invite a new Family Member!</h4>
                    <Form ref={form}>
                        <div>
                            Enter the e-mail address of any Honee-Do user
                            to invite them to the family! They
                            must already be registered with Honee-Do to receive an
                            invite. If they haven't already, ask them to sign up!
                        </div>
                        <div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    value={email}
                                    onChange={onChangeEmail}
                                    validations={[required, validEmail]}
                                />
                            </div>
                            <p />
                            <div className="form-group">
                                <button
                                    className={"btn btn-success" + (isEmail(email) ? "" : " disabled")}
                                    onClick={handleInvite}
                                    style={{
                                        "--bs-btn-line-height": "1.3",
                                        "--bs-btn-border-radius": "4rem",
                                        "--bs-btn-padding-x": "0.95rem"
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-envelope-check" viewBox="0 0 16 16">
                                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                    </svg>
                                    <div style={{
                                        "fontSize": "10px"
                                    }}>
                                        <strong>Send</strong>
                                    </div>
                                </button>
                                <button
                                    className="btn btn-secondary ms-2"
                                    style={{
                                        "--bs-btn-line-height": "1.3",
                                        "--bs-btn-border-radius": "4rem",
                                        "--bs-btn-padding-x": "0.95rem"
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                                        <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                                        <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                                    </svg>
                                    <div style={{
                                        "fontSize": "10px"
                                    }}>
                                        <Link to={"/family"} className="nav-link">
                                            <strong>Cancel</strong>
                                        </Link>
                                    </div>
                                </button>
                            </div>
                            <p />
                        </div>
                        {loading ?
                            <div className="spinner-border text-primary" style={{ "width": "5rem", "height": "5rem" }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            :
                            <div>
                                {message && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                successful ? "alert alert-success" : "alert alert-danger"
                                            }
                                            role="alert"
                                        >
                                            {message}
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                </div>
            </div>
        </FadeIn>
    );
};