import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
import TaskService from "../../services/task.service";
import FamilyService from "../../services/family.service";
import EventBus from "../../common/EventBus";
import "../../App.css"
import Avatar from "react-avatar";
import { Capacitor } from '@capacitor/core';


export const NavBarBrand = () => {
    let navigate = useNavigate();
    const [showAuthenticated, setShowAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [fullName, setFullName] = useState("");
    const [newNotifications, setNewNotifications] = useState(false);
    const [newRequests, setNewRequests] = useState(false);
    const [loading, setLoading] = useState(false);
    const [platform, setPlatform] = useState("");
    const [requests, setRequests] = useState([]);
    const user = AuthService.getCurrentUser();

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            setPlatform("mobile");
        } else {
            console.log("web platform");
        }

        //setFullName(user.firstname + " " + user.lastname)

        if (user) {
            setCurrentUser(user);
            setShowAuthenticated(user.roles.includes("ROLE_ADMIN"));
        }
        getNotifications();

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);

    const getNotifications = () => {
        setLoading(true);
        TaskService.getNotifications(currentUser.userId)
            .then(response => {
                setNotifications(response.data)
                let anyNotSeen = response.data.some(element => {
                    if (element.seen === 0) {
                        return true;
                    }

                    return false;
                });
                console.log("New notifications: ", anyNotSeen);
                setNewNotifications(anyNotSeen);
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const markAsSeen = () => {
        TaskService.markAsSeen()
            .then(response => {
                setNewNotifications(false);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const logOut = () => {
        AuthService.logout();
        setShowAuthenticated(false);
        setCurrentUser([]);
        navigate("/login");
        window.location.reload();
    };

    return (
        <div className="container-fluid">
            {currentUser.userId ?
                <Link to={"/tasks"} className="navbar-brand">
                    <span style={{
                        "font-family": 'Pacifico',
                        "font-size": '18px'
                    }}
                    >
                        Honee-Do!
                    </span>
                </Link>
                :
                <Link to={"/login"} className="navbar-brand">
                    <span style={{
                        "font-family": 'Pacifico',
                        "font-size": '18px'
                    }}
                    >
                        Honee-Do!
                    </span>
                </Link>
            }
            {currentUser.userId &&
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown text-center ms-2">
                            <a className="nav-link" role="button" data-bs-toggle="dropdown" onClick={() => { markAsSeen() }}
                                aria-expanded="false">
                                {!loading ?
                                    <div>
                                        {newNotifications === true ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="goldenrod" class="bi bi-bell-fill mt-1" viewBox="0 0 16 16">
                                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-bell mt-1" viewBox="0 0 16 16">
                                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                            </svg>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-bell mt-1" viewBox="0 0 16 16">
                                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                        </svg>
                                    </div>
                                }
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end" style={{
                                "font-size": "12px"
                            }}>
                                <div className="container"
                                    style={{
                                        "height": '200px',
                                        "overflow-y": 'scroll',
                                    }}
                                >
                                    {notifications && notifications.length ?
                                        notifications.map((notification, index) => (
                                            <li><a key={"a" + index} className="dropdown-item border-bottom" href={"#/" + notification.link}>
                                                {notification.seen == 0 ?
                                                    <strong>
                                                        {notification.description}
                                                    </strong>
                                                    :
                                                    <div>
                                                        {notification.description}
                                                    </div>
                                                }
                                            </a></li>
                                        ))
                                        :
                                        <li><a className="dropdown-item">
                                            No new activity
                                        </a></li>
                                    }

                                </div>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                {currentUser.mediaLink.length > 77 ?
                                    <img className="img border border-light" src={currentUser.mediaLink} alt={currentUser.mediaLink} height="33px" />
                                    :
                                    <Avatar name={currentUser.firstname + " " + currentUser.lastname} color="gray" round={true} size="33" textSizeRatio={3} />
                                }
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><a className="dropdown-item" href="#/profile">
                                    My Settings
                                </a></li>
                                <li><a className="dropdown-item" href="#/login" onClick={logOut}>
                                    Sign Out
                                </a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            }
        </div>
    );
};