import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import AuthService from "../services/auth.service";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const VerifyEmail = () => {
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.verify(email).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  return (
    <div className="container-fluid" style={{
      "max-width": '300px'
    }}>
      <p />
      <p />
      <FadeIn>
        <h1 className="mt-5" style={{
          "font-size": '50px',
          "color": '#5C80BC',
          "font-family": 'Pacifico'
        }}
        >
          <center>
            Honee-Do!
          </center>
        </h1>
        <div style={{
          //"color": 'white'
        }}>
          <FadeIn>
            <span>
              Start using Honee-Do now! It's easy to sign up and completely FREE!
              Enter your email to get started. 
            </span>
            <Form onSubmit={handleRegister} ref={form}>
              {!successful && (
                <div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[required, validEmail]}
                    />
                  </div>
                  <p/>
                  <div className="form-group">
                        {email === 0 ?
                          <div className="d-grid gap-2">
                            <button className="btn btn-success btn-block disabled">Sign Up</button>
                            <button className="btn btn-secondary">
                              <Link to={"/login"} className="nav-link">
                                Return to Sign In
                              </Link>
                            </button>
                          </div>
                          :
                          <div className="d-grid gap-2">
                            <button className="btn btn-success btn-block">Submit</button>
                            <button className="btn btn-secondary">
                              <Link to={"/login"} className="nav-link">
                                Return to Sign In
                              </Link>
                            </button>
                          </div>
                        }
                      </div>
                </div>
              )}
              <p />
              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                  <p />
                  <button className="btn btn-success">
                    <Link to={"/login"} className="nav-link">
                      Return to Sign In
                    </Link>
                  </button>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </FadeIn>
        </div>
      </FadeIn>
    </div>
  );
};

export default VerifyEmail;