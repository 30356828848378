import React, { useState, useEffect } from "react";
import FamilyService from "../services/family.service";
import FadeIn from 'react-fade-in';
import Avatar from "react-avatar";
import { Link, useNavigate } from 'react-router-dom';
import AuthService from "../services/auth.service";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const ReassignAdmin = () => {
    const currentUser = AuthService.getCurrentUser();
    const [members, setMembers] = useState([]);
    const [currentMember, setCurrentMember] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    let navigate = useNavigate();

    const [showReassignAdmin, setShowReassignAdmin] = useState(false);
    const handleReassignAdminOpen = () => setShowReassignAdmin(true);
    const handleReassignAdminClose = () => setShowReassignAdmin(false);

    useEffect(() => {
        retrieveMembers();
    }, []);

    const retrieveMembers = () => {
        FamilyService.listMembers()
            .then(response => {
                setMembers(response.data);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const setActiveMember = (member, index) => {
        if (currentIndex === index) {
            setCurrentMember(null)
            setCurrentIndex(-1)
        } else {
            setCurrentMember(member);
            setCurrentIndex(index);
        }
    };

    const assignNewAdmin = () => {
        handleReassignAdminClose();
        FamilyService.assignNewAdmin(currentMember.userId)
            .then(response => {
                AuthService.logout();
                navigate("/login");
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div className="list row">
            <FadeIn>
                <div className="col-md-6">
                    <h4>Reassign Admin</h4>
                    Select the new Family Admin.
                    <p></p>
                    <div className="accordion" id="accordionFamily">
                        {members &&
                            members.map((member, index) => (
                                <div key={"a" + index} className="accordion-item">
                                    <h2 className="accordion-header" id={"a" + index}>
                                        <button
                                            className={"accordion-button" + (index === currentIndex ? "" : " collapsed")}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={"a" + index}
                                            aria-expanded={(index === currentIndex ? "true" : "false")}
                                            aria-controls={"a" + index}
                                            onClick={() => setActiveMember(member, index)}
                                        >
                                            <div className="pe-2">
                                                {member.mediaLink.length > 77 ?
                                                    <img className="img" src={member.mediaLink} alt={member.mediaLink} height="33px" />
                                                    :
                                                    <Avatar name={(member.firstname != null ? member.firstname + " " + member.lastname : null)} round={true} size="33" textSizeRatio={1.75} />
                                                }
                                            </div>
                                            <div>
                                                {member.firstname} {member.lastname}
                                            </div>
                                        </button>
                                    </h2>
                                    <div id={"a" + currentIndex}
                                        className={"accordion-collapse collapse" + (index === currentIndex ? " show" : "")}
                                        aria-labelledby={"a" + currentIndex} data-bs-parent={"a" + currentIndex}>
                                        <div className="accordion-body">
                                            <div>
                                                {currentUser.userId == member.userId ?
                                                    <strong>You're already the Family Admin!</strong>
                                                    :
                                                    <button className="btn btn-warning mr-2"
                                                    onClick={handleReassignAdminOpen}
                                                        style={{
                                                            "--bs-btn-line-height": "1.3",
                                                            "--bs-btn-border-radius": "4rem",
                                                            "--bs-btn-padding-x": "0.95rem",
                                                            "fontSize": "10px"
                                                        }}>
                                                        <Link to={"/family/reassign_admin"} className="nav-link">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-down me-2" viewBox="0 0 16 16">
                                                                <path d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Zm.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V10.5a.5.5 0 0 0-1 0v2.793l-.646-.647a.5.5 0 0 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                                                <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                                            </svg>
                                                            <strong>Select</strong>
                                                        </Link>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <Modal centered show={showReassignAdmin} onHide={handleReassignAdminClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Select {member.firstname}?</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <span>Are you sure you want to make {member.firstname} the new
                                                        Family Admin? You will lose your Family Admin privileges
                                                        and will need to log back in to Honee-Do afterward.</span>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleReassignAdminClose}>
                                                    Close
                                                </Button>
                                                <Button variant="danger" onClick={assignNewAdmin}>                        
                                                Confirm new Family Admin     
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <p />
                    <button
                        className="btn btn-secondary ms-2"
                        style={{
                            "--bs-btn-line-height": "1.3",
                            "--bs-btn-border-radius": "4rem",
                            "--bs-btn-padding-x": "0.95rem"
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16">
                            <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z" />
                            <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z" />
                        </svg>
                        <div style={{
                            "fontSize": "10px"
                        }}>
                            <Link to={"/family"} className="nav-link">
                                <strong>Cancel</strong>
                            </Link>
                        </div>
                    </button>
                </div>
            </FadeIn>
        </div>
    );
};