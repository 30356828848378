import React, { useEffect } from "react";
import { ListMembers } from "../components/list-members";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

export const MyFamily = () => {
    const currentUser = AuthService.getCurrentUser();
    let navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        } else {
            AuthService.refresh(currentUser.userId); 
            if (currentUser.familyId === null) {
            navigate("/needfamily")
            }
        }
    }, []);

    return (
        <ListMembers />
    );
};