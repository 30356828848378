import React, { useRef, useState } from "react";
import FadeIn from 'react-fade-in';
import AuthService from "../services/auth.service";
import FamilyService from "../services/family.service";
import { Link, useNavigate } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const CreateFamily = () => {
    const form = useRef();
    const checkBtn = useRef();
    const currentUser = AuthService.getCurrentUser();
    const initialFamilyState = {
        familyId: null,
        familyName: "",
        userId: currentUser.userId,
    };
    const [family, setFamily] = useState("");
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [change, setChange] = useState(false);
    let navigate = useNavigate();

    const [showCreateFamily, setShowCreateFamily] = useState(false);
    const handleCreateFamilyOpen = () => setShowCreateFamily(true);
    const handleCreateFamilyClose = () => setShowCreateFamily(false);

    const required = (value) => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert">
                    You must give your family a name!
                </div>
            );
        }
    };

    const vFamilyName = (value) => {
        if (value.length > 20) {
            return (
                <div className="alert alert-danger" role="alert">
                    Family name can't exceed 20 characters!
                </div>
            );
        }
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setFamily({ ...family, [name]: value });
        setChange(true);
    };

    const saveFamily = (e) => {
        handleCreateFamilyClose();
        var data = {
            familyName: family.familyName,
            userId: initialFamilyState.userId,
        };
        var userid = currentUser.userId;

        e.preventDefault();

        setMessage("");
        setSuccessful(false);

        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            FamilyService.create(data).then(
                (response) => {
                    setFamily({
                        familyId: response.data.familyId,
                        familyName: response.data.familyName,
                        userId: initialFamilyState.userId
                    });
                    setMessage(response.data.message);
                    setSuccessful(true);
                    AuthService.refresh(userid).then(
                        () => {
                            navigate("/family");
                            window.location.reload();
                        },
                        (error) => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            setMessage(resMessage);
                        }
                    );
                    navigate("/family");
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                }
            );
        }
    };

    return (
        <div>
            <FadeIn>
                <div className="list row">
                    <div className="col-md-6">
                        <p />
                        <FadeIn>
                            <h4>Create your Family!</h4>
                            <span>
                                Give your family a name! Something like "Smith", or "Martinez",
                                or "Awesome Ones" - then hit the Create button!
                            </span>
                            <p />
                            <span>
                                You will be made the Family Admin
                                and will be able to invite other Honee-Do users.
                            </span>
                            <Form onSubmit={saveFamily} ref={form}>
                                <div className="form-group">
                                    <label htmlFor="familyName">Enter the Family Name below.</label>
                                    <p />
                                    <h4 style={{ "margin-bottom": "0" }}>
                                        The
                                    </h4>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="familyName"
                                        value={family.familyName}
                                        validations={[required, vFamilyName]}
                                        onChange={handleInputChange}
                                        name="familyName"
                                    />
                                    <h4 className="mt-1">
                                        Family!
                                    </h4>
                                </div>
                                <p />
                                <div className="btn btn-success"
                                    onClick={handleCreateFamilyOpen}
                                >
                                    Create!
                                </div>
                                <button className="btn btn-secondary ms-3">
                                    <Link to={"/needfamily"} className="nav-link">
                                        Cancel
                                    </Link>
                                </button>
                                <Modal centered show={showCreateFamily} onHide={handleCreateFamilyClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create Family?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <span>Are you sure you want to create the {family.familyName} family?</span>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCreateFamilyClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="success" onClick={saveFamily}>
                                            Yes, Create!
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {message && (
                                    <div className="form-group">
                                        <div
                                            className={
                                                successful ? "alert alert-success" : "alert alert-danger"
                                            }
                                            role="alert"
                                        >
                                            {message}
                                        </div>
                                    </div>
                                )}
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />
                            </Form>
                        </FadeIn>
                    </div>
                </div>
            </FadeIn>
        </div>
    );
};