import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import authService from './services/auth.service';

const firebaseConfig = {
    apiKey: "AIzaSyANJvfYRn5YjX-C9_dGUxYFBzzp4cqxXss",
    authDomain: "honee-do.firebaseapp.com",
    projectId: "honee-do",
    storageBucket: "honee-do.appspot.com",
    messagingSenderId: "938421251908",
    appId: "1:938421251908:web:96d17ea355e6194d52f111",
    measurementId: "G-9KTT7EF8T3"
};

const app = initializeApp(firebaseConfig);
let messaging;
try {
    messaging = getMessaging(app);
} catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
}
//const messaging = getMessaging(app);
const currentUser = authService.getCurrentUser();

export const requestForToken = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BBbgf9SJtMjLgwOUcrmAhU-YboZtfHsbX_BMAtsnXPxGr2KYyOt5S4GYj0n6Lb4zYyKVlHFr2fWTjMPEbPk_Gz8'}).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            authService.sendFirebaseToken(currentUser.userId, currentToken)
            .then(response => {
              console.log(response.data)
            })
            .catch(e => {
              console.log(e);
            });

        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const getNewToken = async () => {
    let currentToken = '';
    try {
        currentToken = await getToken(messaging, {vapidKey: "BBbgf9SJtMjLgwOUcrmAhU-YboZtfHsbX_BMAtsnXPxGr2KYyOt5S4GYj0n6Lb4zYyKVlHFr2fWTjMPEbPk_Gz8"});
        if (currentToken) {
            console.log(currentToken);
            //setTokenFound(true);
        } else {
            //setTokenFound(false);
        }
    } catch (error) {
        console.log('An error occurred while retrieving token.', error);
    }
    return currentToken;
};


export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

initializeApp(firebaseConfig);